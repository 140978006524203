<template>
  <div>
    <div class="verify-id-form" v-if="(idMatrix.verified === true || index > 0) && page_type === 'paylater' && (formData.payment_type !== 'now' || (idMatrix.verifyCount === 3 && formData.payment_type === 'now' ))">
      <vx-card class="mt-4 md:mt-6">
        <div class="card-title clearfix">
          <vs-col vs-w="9">
            <h5 class="text-lg lg:text-xl font-normal card-title">Verify ID</h5>
          </vs-col>
          <vs-col vs-w="3" class="text-right">
            <span class="taskDone"><img v-if="idMatrix.verified === true" :src="checkCircle" alt="Done" /></span>
          </vs-col>
        </div>
      </vx-card>
    </div>

    <div class="verify-id-inputs mt-4 md:mt-6 mb-4 md:mb-6" v-if="idMatrix.verified === false && idMatrix.verifyCount > 2 && index == 0 && showConfirmationForSuccess == false">
      <vx-card :class=" page_type === 'paylater' && showIdMatrixVerification ? 'login-block' : 'hiddenDiv'"></vx-card>

      <vx-card :class=" page_type === 'paylater' && showIdMatrixVerification ? 'lg:m-2.5 verify-block sm:m-2.5' : 'hiddenDiv lg:m-2.5 sm:m-2.5'">
        <div class="card-title mb-5">
          <vs-col vs-w="9">
            <h4 class="text-lg lg:text-xl font-normal card-title">Verify ID</h4>
          </vs-col>
        </div>
        <p class="pt-4 leading-normal font-light text-sm md:text-base">
          We’re required by law to verify your identity before confirming a “Pay Later” offer. Your personal information is handled according to our
          <a class="cursor-pointer" @click="navigateToNewTab()">privacy policy</a>, and is only used for identity verification & credit assessment purposes.
        </p>
        <hr class="my-8 line-hr" />

        <div class="idFailure lg:w-9/12 w-full m-auto">
          <div class="flex">
            <div class="flex">
              <alert-circle-icon stroke="#EB5757" height="20px"></alert-circle-icon>
            </div>
            <div class="flex flex-col ml-4">
              <h4 class="text-base font-normal p-0">Sorry, we can’t offer you a payment plan</h4>
              <p class="block text-sm">Unfortunately you can’t proceed with this payment plan as we’re unable to verify your ID. You can Pay Now instead.</p>
            </div>
          </div>
        </div>
      </vx-card>
    </div>
    <div class="verify-id-inputs box-shadow mt-4 md:mt-6 mb-4 md:mb-6" v-show="index == 0" v-if="idMatrix.verified === null || (idMatrix.verified === false && idMatrix.verifyCount < 3)">
      <vx-card :class=" page_type === 'paylater' && formData.payment_type !== 'now' && showIdMatrixVerification ? 'login-block rounded-bl-none rounded-br-none' : 'hiddenDiv rounded-bl-none rounded-br-none'"></vx-card>

      <vx-card :class=" page_type === 'paylater' && formData.payment_type !== 'now' && showIdMatrixVerification ? 'lg:m-2.5 verify-block sm:m-2.5' : 'hiddenDiv lg:m-2.5 sm:m-2.5'">
        <div class="card-title clearfix">
          <vs-col vs-w="9">
            <h4 class="text-lg lg:text-xl font-normal card-title mb-0">Verify ID</h4>
          </vs-col>
        </div>
        <p class="pt-4 leading-normal font-light text-sm md:text-base">
          We’re required by law to verify your identity before confirming a “Pay Later” offer. Your personal information is handled according to our
          <a class="cursor-pointer" @click="navigateToNewTab()">privacy policy</a>, and is only used for identity verification & credit assessment purposes.
        </p>
        <hr class="mt-6 mb-8 lg:my-8 line-hr" />
        <div class="idFailure lg:w-9/12 w-full m-auto mb-6 md:mb-8 lg:mb-10" v-if="idMatrix.verifyCount > 0 && idMatrix.verifyCount < 3 && !hideIdErrorBlock">
          <div class="flex">
            <div class="flex">
              <alert-circle-icon stroke="#EB5757" height="20px"></alert-circle-icon>
            </div>
            <div class="flex flex-col ml-4">
              <h4 class="text-base font-normal p-0 mb-2">
                {{ idMatrix.verifyCount === 3 ? "Sorry, we can’t offer you a payment plan" : "Verification failed" }}
              </h4>
              <p class="block text-sm">
                {{
                  idMatrix.verifyCount === 3
                    ? "Unfortunately you can’t proceed with this payment plan as we’re unable to verify your ID. You can Pay Now instead."
                    : "We are unable to verify your ID using the below information. Please check the details entered, and/or consider using a different ID document. If we are unable to verify your ID we won’t be able to offer you a payment plan."
                }}
              </p>
            </div>
          </div>
        </div>
        <div class="card-title mb-6 lg:mb-8">
          <strong class="text-base font-normal">Your residential address</strong>
        </div>
        <div class="mx-0 lg:mx-6">
          <vs-row class="mb-6 lg:mb-8">
            <vs-col vs-w="12">
              <label class="w-full text-sm md:text-base font-light">Address</label>
              <vue-google-autocomplete ref="address" id="map" classname="w-full custom-w" v-on:placechanged="getAddressData" :country="['au']" placeholder="Start typing your address">
              </vue-google-autocomplete>
            </vs-col>
          </vs-row>

          <vs-row class="mb-6 lg:mb-8">
            <vs-col vs-w="12" class="pr-xs-0 mt-0">
              <label class="text-sm md:text-base font-light w-full">Apt, Suite, Unit, Building, Floor <span class="text-sm md:text-base font-light text-gray">(optional)</span></label>
              <vs-input class="w-full custom-w" v-model="formData.unit_number" name="unit_number"/>
              <span class="text-danger text-sm" v-show="errors.has('unit_number')">{{ errors.first("unit_number") }}</span>
            </vs-col>
          </vs-row>

          <vs-row class="mb-6 lg:mb-8">
            <vs-col vs-w="12" class="pr-xs-0 mt-0">
              <label class="text-sm md:text-base font-light w-auto">Street address</label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('street_name')">{{errors.first("street_name")}}</span>
                <vs-input class="w-full custom-w add-street" v-model="formData.street_name" name="street_name" v-validate="'required'"/>
              </div>
            </vs-col>
          </vs-row>

          <vs-row class="grid gap-x-4 md:gap-x-8 grid-cols-4 add-info-force">
            <vs-col class="mb-6 lg:mb-8 suburbField col-span-4 md:col-span-2">
              <label class="text-sm md:text-base font-light">Suburb</label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('suburb')">{{ errors.first("suburb") }}</span>
                <vs-input class="w-full custom-w" v-model="formData.suburb" name="suburb" v-validate="'required'"/>
              </div>
            </vs-col>

            <vs-col class="mb-6 lg:mb-8 stateField col-span-2 md:col-span-1">
              <label class="w-full text-sm md:text-base font-light">State</label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('state')">{{ errors.first("state") }}</span>
                <vs-select autocomplete v-model="formData.state" class="w-full custom-w" name="state" v-validate="'required'" laceholder="Select state">
                  <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in states"></vs-select-item>
                </vs-select>
              </div>
            </vs-col>

            <vs-col class="mb-6 lg:mb-8 postcodeField col-span-2 md:col-span-1">
              <label class="w-full text-sm md:text-base font-light">Postcode</label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('postcode')">{{ errors.first("postcode") }}</span>
                <the-mask class="w-full custom-w vs-input--input" type="string" masked :mask="['####']" v-model="formData.postcode" name="postcode" v-validate="'required'"/>
              </div>
            </vs-col>
          </vs-row>
        </div>

        <div class="card-title pt-2 lg:pt-6 mb-4 lg:mb-8">
          <h3 class="text-base font-normal">Your identification document</h3>
        </div>

        <div class="mx-0 lg:mx-6">
          <vs-row class="custom-radio-wrap">
            <vs-col class="mb-4">
              <vs-radio vs-value="DL" class="w-full items-center" vs-name="id_type" v-model="formData.id_type">
                <span class="ml-2 md:ml-4 text-sm md:text-base">Australian driver licence</span>
              </vs-radio>
            </vs-col>
            <vs-col class="mb-0 lg:mb-1">
              <vs-radio vs-value="PP" class="w-full items-center text-base font-normal" vs-name="id_type" v-model="formData.id_type">
                <span class="ml-2 md:ml-4 text-sm md:text-base">Australian Passport</span>
              </vs-radio>
            </vs-col>
          </vs-row>

          <hr class="my-4 lg:my-6 w-full line-hr" />

          <p class="text-sm leading-tight md:text-base font-light">Enter the following information as it appears on your selected document</p>

          <vs-row class="personalInformation mt-8 md:grid md:gap-x-10 md:grid-cols-2">
            <vs-col class="mb-6 lg:mb-8">
              <label class="w-full text-sm md:text-base font-light">First name</label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('first_name')">{{ errors.first("first_name") }}</span>
                <vs-input class="w-full custom-w" v-model="formData.first_name" name="first_name" v-validate="'required'" />
              </div>
            </vs-col>

            <vs-col class="mb-6 lg:mb-8">
              <label class="w-full text-sm md:text-base font-light">Middle name</label>
              <vs-input class="w-full custom-w" v-model="formData.middle_name" name="middle_name" />
              <span class="text-danger text-sm" v-show="errors.has('middle_name')">{{ errors.first("middle_name") }}</span>
            </vs-col>

            <vs-col class="mb-6 lg:mb-8">
              <label class="w-full text-sm md:text-base font-light">Last name</label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('last_name')">{{ errors.first("last_name") }}</span>
                <vs-input class="w-full custom-w" v-model="formData.last_name" name="last_name" v-validate="'required'"/>
              </div>
            </vs-col>
            <vs-col class="mb-6 lg:mb-8">
              <div class="vs-con-input">
                <label class="w-auto text-sm md:text-base font-light">Date of birth</label>
                <div class="input-container">
                  <span class="text-danger text-sm" v-show="errors.has('date_of_birth')">{{ errors.first("date_of_birth") }}</span>
                  <date-picker
                    v-model="formData.date_of_birth"
                    format="DD/MM/YYYY"
                    valuetype="format"
                    placeholder="DD/MM/YYYY"
                    name="date_of_birth"
                    id="date_of_birth"
                    v-validate="'required|18-years-age'"
                    :disabled-days="(date) => date >= new Date()"
                    input-class="date-picker-input w-full"
                    lang="jp"
                  ></date-picker>
                </div>
              </div>
            </vs-col>
          </vs-row>

          <vs-row v-if="formData.id_type === 'DL'" vs-w="12" key="license_custom_fields" class="md:grid md:gap-x-10 md:grid-cols-2">
            <vs-col class="mb-6 lg:mb-8">
              <label class="w-auto text-sm md:text-base font-light">Licence number</label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('license_number')">{{ errors.first("license_number") }}</span>
                <vs-input class="w-full custom-w" v-model="formData.licenseDetails.license_number" name="license_number" v-validate="this.formData.id_type === 'DL' ? 'required' : ''" key="license_number" />
              </div>
            </vs-col>
            <vs-col class="mb-6 lg:mb-8">
              <div class="vs-con-input">
                <label class="w-auto text-sm md:text-base font-light">State of issue</label>
                <div class="input-container">
                  <span class="text-danger text-sm" v-show="errors.has('license_state')">{{ errors.first("license_state") }}</span>
                  <vs-select autocomplete v-model="formData.licenseDetails.state" class="custom-w full-width-select" name="license_state" key="license_state" v-validate="this.formData.id_type === 'DL' ? 'required' : ''">
                    <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in states" placeholder="Select state"></vs-select-item>
                  </vs-select>
                </div>
              </div>
            </vs-col>
            <vs-col class="mb-6 lg:mb-8">
              <div class="vs-con-input">
                <label class="w-auto text-sm md:text-base font-light flex items-center">Licence card number
                  <popper
                    trigger="hover"
                    :options="{
                      placement: 'top',
                      modifiers: { offset: { offset: '100px, 2px' } }
                    }">
                    <my-tooltip :state="formData.licenseDetails.state" :width="'auto'"/>
                    <span slot="reference"><img :src="questionIcon" v-if="formData.licenseDetails.state" class="pl-2 cursor-pointer"/></span>
                  </popper>
                </label>

                <div class="input-container">
                  <span class="text-danger text-sm" v-show="errors.has('license_card_number')">{{ errors.first("license_card_number") }}</span>
                  <vs-input class="w-full custom-w" v-model="formData.licenseDetails.card_number" name="license_card_number" v-validate="!notRequiredStates.includes(formData.licenseDetails.state) ? 'required' : ''" />
                </div>
              </div>
            </vs-col>

            <vs-col class="mb-6 lg:mb-8">
              <label class="text-sm md:text-base font-light">Expiry date</label>
              <div class="">
                <date-picker
                  v-model="formData.licenseDetails.expiry_date"
                  placeholder="DD/MM/YYYY"
                  format="DD/MM/YYYY"
                  valuetype="format"
                  name="license_expiry_date"
                  id="license_expiry_date"
                  v-validate="this.formData.id_type === 'DL' ? 'required' : ''"
                  key="license_expiry_date"
                  :disabled-days="(date) => date <= new Date()"
                  input-class="date-picker-input w-full custom-w"
                  lang="jp"
                ></date-picker>
                <span class="text-danger text-sm" v-show="errors.has('license_expiry_date')">{{ errors.first("license_expiry_date") }}</span>
              </div>
            </vs-col>
          </vs-row>

          <vs-row v-if="formData.id_type === 'PP'" key="passport_custom_fields" vs-w="12" class="md:grid md:gap-x-10 md:grid-cols-2">
            <vs-col class="mb-6 lg:mb-8">
              <label class="w-full text-sm md:text-base font-light">Passport number</label>
              <div class="input-container">
                <span class="text-danger text-sm" v-show="errors.has('passport_number')">{{ errors.first("passport_number") }}</span>
                <vs-input
                  class="w-full custom-w"
                  v-model="formData.passportDetails.passport_number"
                  name="passport_number"
                  key="passport_number"
                  v-validate="this.formData.id_type === 'PP' ? 'required' : ''"
                />
              </div>
            </vs-col>

            <vs-col class="mb-6 lg:mb-8">
              <label class="w-full text-sm md:text-base font-light">Issue Date</label>
              <div>
                <date-picker v-model="formData.passportDetails.issue_date" placeholder="DD/MM/YYYY" format="DD/MM/YYYY" valuetype="format" name="passport_issue_date"
                  id="passport_issue_date" v-validate="this.formData.id_type === 'PP' ? 'required' : ''" key="passport_issue_date" :disabled-days="(date) => date >= new Date()"
                  input-class="date-picker-input w-full" lang="jp"></date-picker>
                <span class="text-danger text-sm" v-show="errors.has('passport_issue_date')" >{{ errors.first("passport_issue_date") }}</span>
              </div>
            </vs-col>

            <vs-col class="mb-6 lg:mb-8">
              <label class="w-full text-sm md:text-base font-light">Expiry date</label>
              <div>
                <span class="text-danger text-sm" v-show="errors.has('passport_expiry_date')">{{ errors.first("passport_expiry_date") }}</span>
                <date-picker v-model="formData.passportDetails.expiry_date" placeholder="DD/MM/YYYY" format="DD/MM/YYYY" valuetype="format" name="passport_expiry_date"
                  id="passport_expiry_date" v-validate="this.formData.id_type === 'PP' ? 'required' : ''" key="passport_expiry_date" :disabled-days="(date) => date <= new Date()"
                  input-class="date-picker-input" lang="jp"></date-picker>
              </div>
            </vs-col>
          </vs-row>

          <vs-row class="">
            <vs-col vs-w="12" vs-xs="12">
              <vs-checkbox name="test" class="mr-0 check-h items-start h-auto" v-model="autorized">
                <p class="text-xs md:text-sm leading-tight ml-2 md:ml-4">
                  I am authorised to provide the above information. I consent to this being checked with the document issuer/official record
                  holder via third parties for the purpose of confirming my identity
                </p>
              </vs-checkbox>
            </vs-col>
          </vs-row>

          <vs-row vs-justify="center" class="mt-6 lg:mt-8 mb-4">
            <vs-button
              class="w-full large"
              v-round
              @click="startValidating"
              :disabled="
                !authorized_to_use ||
                idMatrix.verified ||
                !validateForm ||
                formData.id_type == ''
              "
            >
              Verify Id
            </vs-button>
          </vs-row>
        </div>
      </vx-card>
    </div>
  </div>

</template>

<script>
import DatePicker from "vue2-datepicker";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import { TheMask } from "vue-the-mask";
import { AlertCircleIcon } from "vue-feather-icons";
import MyTooltip from './components/myTooltip.vue';
import Popper from 'vue-popperjs';
import 'vue-popperjs/dist/vue-popper.css';
const questionIcon = require("@/assets/images/toolbox.svg");

export default {
  components: {
    DatePicker,
    VueGoogleAutocomplete,
    TheMask,
    AlertCircleIcon,
    MyTooltip,
    'popper': Popper,
  },
  props: [
    "formData",
    "idMatrix",
    "authorized_to_use",
    "showIdMatrixVerification",
    "page_type",
    "states",
    "addressData",
    "checkCircle",
    "xCircle",
    "index",
    "submitVerifyId",
    "showConfirmationForSuccess",
    "privacy",
    "hideIdErrorBlock",
  ],
  data() {
    return {
      appUrl: process.env.VUE_APP_ROOT_API,
      autorized: false,
      notRequiredStates: [ // TODO: this will require revision in 2023
        'QLD',
        'VIC'
      ],
      questionIcon: questionIcon,
    };
  },
  beforeUpdate() {
    if (this.showIdMatrixVerification) {
    }
  },
  methods: {
    navigateToNewTab() {
      const url = `${this.appUrl}pages/terms-and-conditions/${this.privacy._id}?type=DASHBOARD&fullDocument=false`;
      window.open(url, "_blank");
    },
    getAddressData: function (addressData, placeResultData, _id) {
      this.$emit("changeAddress", addressData, placeResultData, _id);
    },
    showLoginFrom() {
      this.$emit("loginIndex");
    },
    async startValidating() {
      this.$validator.validateAll().then(async (result) => {
        if (result) {
          this.$emit("verifyId");
        }
      });
    },
  },
  computed: {
    validateForm() {
      return !this.errors.any();
    },
  },
  watch:{
    autorized(val){
      this.$emit("idVerifyAuthorized", val);
    }
  }
};
</script>