<!-- =========================================================================================
    File Name: TheFooter.vue
    Description: Footer component
    Component Name: TheFooter
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <footer class="the-footer big-footer flex-wrap justify-between" :class="classes">
    <div class="lg:px-10 md:px-2 px-0 w-full pb-4">
      <vs-row class="mb-2">
        <span v-if="pageBranding" class="text-xl font-medium">{{ pageBranding.companyName }}</span>
      </vs-row>
      <vs-row class="flex items-center for-mobile-view">
        <span class="flex items-center footer-items" v-if="pageBranding && pageBranding.companyAbn">
          <span class="text-base font-medium">ABN:&nbsp;</span>
          {{ pageBranding.companyAbn }}
        </span>
        <span class="flex items-center footer-dots mx-8" v-if="pageBranding && pageBranding.companyAbn"></span>
        <span class="flex items-center footer-items" v-if="pageBranding && pageBranding.companyAddress">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_3801_40)">
              <path
                d="M17.5 8.83337C17.5 14.6667 10 19.6667 10 19.6667C10 19.6667 2.5 14.6667 2.5 8.83337C2.5 6.84425 3.29018 4.9366 4.6967 3.53007C6.10322 2.12355 8.01088 1.33337 10 1.33337C11.9891 1.33337 13.8968 2.12355 15.3033 3.53007C16.7098 4.9366 17.5 6.84425 17.5 8.83337Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <path
                d="M10 11.3334C11.3807 11.3334 12.5 10.2141 12.5 8.83337C12.5 7.45266 11.3807 6.33337 10 6.33337C8.61929 6.33337 7.5 7.45266 7.5 8.83337C7.5 10.2141 8.61929 11.3334 10 11.3334Z"
                stroke="white"
                stroke-width="2"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </g>
            <defs>
              <clipPath id="clip0_3801_40">
                <rect width="20" height="20" fill="white" transform="translate(0 0.5)" />
              </clipPath>
            </defs>
          </svg>
          <span v-if="pageBranding" class="ml-2">{{ pageBranding.companyAddress }}</span>
        </span>
        <span class="flex items-center footer-dots mx-8" v-if="pageBranding && pageBranding.companyAddress"></span>
        <span class="flex items-center footer-items" v-if="pageBranding && pageBranding.companyPhone">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M18.3333 14.6V17.1C18.3343 17.3321 18.2867 17.5618 18.1937 17.7745C18.1008 17.9871 17.9644 18.178 17.7934 18.3349C17.6224 18.4918 17.4205 18.6113 17.2006 18.6856C16.9808 18.76 16.7478 18.7876 16.5167 18.7667C13.9523 18.4881 11.4892 17.6118 9.32498 16.2084C7.31151 14.9289 5.60443 13.2218 4.32499 11.2084C2.91663 9.03438 2.04019 6.5592 1.76665 3.98337C1.74583 3.75293 1.77321 3.52067 1.84707 3.30139C1.92092 3.08211 2.03963 2.88061 2.19562 2.70972C2.35162 2.53883 2.54149 2.4023 2.75314 2.30881C2.9648 2.21532 3.1936 2.16692 3.42499 2.16671H5.92499C6.32941 2.16273 6.72148 2.30594 7.02812 2.56965C7.33476 2.83336 7.53505 3.19958 7.59165 3.60004C7.69717 4.4001 7.89286 5.18565 8.17499 5.94171C8.2871 6.23998 8.31137 6.56414 8.24491 6.87577C8.17844 7.18741 8.02404 7.47347 7.79998 7.70004L6.74165 8.75837C7.92795 10.8447 9.65536 12.5721 11.7417 13.7584L12.8 12.7C13.0266 12.476 13.3126 12.3216 13.6243 12.2551C13.9359 12.1887 14.26 12.2129 14.5583 12.325C15.3144 12.6072 16.0999 12.8029 16.9 12.9084C17.3048 12.9655 17.6745 13.1694 17.9388 13.4813C18.203 13.7932 18.3435 14.1914 18.3333 14.6Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
          </svg>
          <span class="ml-2">
            <a v-if="pageBranding" style="text-decoration:none;" :href="`tel:${pageBranding.companyPhone}`">{{ pageBranding.companyPhone }}</a>
          </span>
        </span>
        <span class="flex items-center footer-dots mx-8" v-if="pageBranding && pageBranding.companyPhone"></span>
        <span class="flex items-center footer-items" v-if="pageBranding && pageBranding.companyWeb">
          <svg class="webb" width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M9.99 0.5C4.47 0.5 0 4.98 0 10.5C0 16.02 4.47 20.5 9.99 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 9.99 0.5ZM16.92 6.5H13.97C13.65 5.25 13.19 4.05 12.59 2.94C14.43 3.57 15.96 4.85 16.92 6.5ZM10 2.54C10.83 3.74 11.48 5.07 11.91 6.5H8.09C8.52 5.07 9.17 3.74 10 2.54ZM2.26 12.5C2.1 11.86 2 11.19 2 10.5C2 9.81 2.1 9.14 2.26 8.5H5.64C5.56 9.16 5.5 9.82 5.5 10.5C5.5 11.18 5.56 11.84 5.64 12.5H2.26ZM3.08 14.5H6.03C6.35 15.75 6.81 16.95 7.41 18.06C5.57 17.43 4.04 16.16 3.08 14.5ZM6.03 6.5H3.08C4.04 4.84 5.57 3.57 7.41 2.94C6.81 4.05 6.35 5.25 6.03 6.5ZM10 18.46C9.17 17.26 8.52 15.93 8.09 14.5H11.91C11.48 15.93 10.83 17.26 10 18.46ZM12.34 12.5H7.66C7.57 11.84 7.5 11.18 7.5 10.5C7.5 9.82 7.57 9.15 7.66 8.5H12.34C12.43 9.15 12.5 9.82 12.5 10.5C12.5 11.18 12.43 11.84 12.34 12.5ZM12.59 18.06C13.19 16.95 13.65 15.75 13.97 14.5H16.92C15.96 16.15 14.43 17.43 12.59 18.06ZM14.36 12.5C14.44 11.84 14.5 11.18 14.5 10.5C14.5 9.82 14.44 9.16 14.36 8.5H17.74C17.9 9.14 18 9.81 18 10.5C18 11.19 17.9 11.86 17.74 12.5H14.36Z"
              fill="white"
            />
          </svg>
          <span class="ml-2"><a v-if="pageBranding" style="text-decoration:none;" :href="pageBranding.companyWeb" target="_blank">{{ pageBranding.companyWeb }}</a></span></span
        >
        <span class="flex items-center footer-dots mx-8" v-if="pageBranding && pageBranding.companyWeb"></span>
        <span class="flex items-center footer-items" v-if="pageBranding && pageBranding.companyEmail">
          <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M3.33333 3.83337H16.6667C17.5833 3.83337 18.3333 4.58337 18.3333 5.50004V15.5C18.3333 16.4167 17.5833 17.1667 16.6667 17.1667H3.33333C2.41666 17.1667 1.66666 16.4167 1.66666 15.5V5.50004C1.66666 4.58337 2.41666 3.83337 3.33333 3.83337Z"
              stroke="white"
              stroke-width="2"
              stroke-linecap="round"
              stroke-linejoin="round"
            />
            <path d="M18.3333 5.5L10 11.3333L1.66666 5.5" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

          <span class="ml-2">
            <a v-if="pageBranding" style="text-decoration:none;" :href="`mailto:${pageBranding.companyEmail}`">{{ pageBranding.companyEmail }}</a>
          </span>
        </span>
      </vs-row>
    </div>
  </footer>
</template>

<script>
export default {
  name: "the-footer",
  props: ["pageBranding"],
  data() {
    return {
      paymentRequestDetail: { type: Object },
      classes: {
        type: String
      }
    };
  },
  inject: {
    components: {
      default: {
      }
    }
  }
};
</script>
<style lang="scss">
.the-footer {
  background: #001d84;
}
.the-footer span,
.the-footer a {
  color: #fff;
  font-size: 12px;
  font-weight: 400;
}
.the-footer .material-icons {
  font-size: 16px;
}
.the-footer .footer-dots {
  height: 5px;
  width: 5px;
  background: white;
  border-radius: 50%;
}
@media (max-width: 1100px) {
  .the-footer .footer-dots {
    display: none !important;
  }
  .the-footer .footer-items {
    margin-right: 32px;
  }
}
@media (max-width: 769px) {
  .mobile-footer{
    margin-top:2.25rem;
  }
  .mobile-footer .the-footer{
    padding:1rem 1.6rem 0;
    margin-bottom:0!important;
  }
  .the-footer .mb-2{
    margin-bottom:5px!important;
  }
  .the-footer .footer-items {
    padding: 0;
    margin:0 0 8px;
  }
  .the-footer .footer-items svg{
    width:15px!important;
    height:15px!important;
  }
  .the-footer .text-xl{
    font-size:1rem!important;
    font-weight: 500!important;
  }
  .the-footer .text-base{
    margin:0 7px 0 0!important;
    color:#bdbdbd;
    font-size:.75rem!important;
    font-weight: 500!important;
  }

  .the-footer span span {
    font-size: .75rem!important;
    margin-left:24px!important;
  line-height:15.6px!important;
    font-weight:300!important;
  }
}

@media (max-width: 500px) {
  .mobile-footer {
    margin-left: 0;
    margin-right: -2r
    em;
  }
}
</style>
