<template>
     <vx-card
          class="mt-4 md:mt-6 md:py-2 text-center sm:text-justify"
          v-if="
            redirectData.success.header || redirectData.success.body || redirectData.success.url && redirectData.success.btnText
          "
        >

          <!-- {{redirectData}} -->

        <div class="flex justify-center mb-4" v-if="redirectData.success.header">
              <h3 class="font-medium text-lg md:text-xl">{{redirectData.success.header}}</h3>
            </div>
          <div class="flex justify-center mb-6 md:mb-8" v-if="redirectData.success.body">

            <p class="text-center font-light text-base leading-normal">
             {{redirectData.success.body}}
            </p>
          </div>

          <vs-row v-if="redirectData.success.url && redirectData.success.btnText">
            <vs-col class="text-center mb-2 md:mb-4">
              <vs-button
                class="w-auto px-8 md:w-5/6"
                v-round
                size="large"
                @click="actionButtonClick(redirectData.success.url)"
              >
               {{redirectData.success.btnText}}
              </vs-button>
            </vs-col>
          </vs-row>
        </vx-card>
</template>

<script>
export default {
    name:"successUrl",
    props:["redirectData"],
    data(){
        return{

        }
    },
    methods:{
      actionButtonClick(val){
        
        //window.location.href = val;
        if(val.indexOf("http://") == 0 || val.indexOf("https://") == 0) {
          window.open(
            val,
            '_blank' // <- This is what makes it open in a new window.
          );
        }else{
          window.open(
            "http://" + val,
            '_blank' // <- This is what makes it open in a new window.
          );
        }
      }
    }
}
</script>
