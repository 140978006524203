<template>
  <div class="customer-login-content">
    <vx-card
      class="mt-5 lg:m-2.5 login-block sm:m-2.5"
    >
      <div class="text-xl font-medium  text-center">Log in to your account</div>
    </vx-card>
    <vx-card class="lg:m-2.5 login-form sm:m-2.5">
      <vs-row>
        <vs-col vs-offset="2" vs-w="8" vs-lg="8" vs-sm="8" vs-xs="12">
          <div v-if="error" class="error-message-container">
            <div>{{ error.title }}</div>
            <div>{{ error.message }}</div>
          </div>
        </vs-col>
        <vs-col class="justify-center flex">
          <span class="text-black  text-base">Enter the code we just sent to your mobile {{ phoneNumber }}.</span>
        </vs-col>

    <vs-col class="otp-align">
              <v-otp-input
                ref="otpInput"
                input-classes="otp-input"
                separator=""
                :num-inputs="6"
                :should-auto-focus="true"
                :is-input-num="true"
                @on-change="handleOnChange"
                @on-complete="handleOnComplete"
              />

    </vs-col>
    <vs-col >
        <p class="text-sm text-center">Didn’t get it? <a @click="resendCode">Resend code</a></p>
    </vs-col>
    <vs-col
          class="mt-5 flex flex-col w-full justify-center items-center"

        >
          <vs-button
          style="max-width:370px;"
            class="w-full mb-6"
            v-round
            size="medium"
            @click="login"
            :disabled="otpLoginCode.length < 6"
          >
            Log In
          </vs-button>
          <vs-button
          style="max-width:370px;"
            v-round
            size="medium"
            @click="cancel"
            class="w-full only-border-btn flex justify-center"
          >
            Cancel
          </vs-button>
        </vs-col>
      </vs-row>
    </vx-card>
  </div>
</template>

<script>
import CodeInput from "vue-verification-code-input";

export default {
  components: {
    CodeInput,
  },
  props: {
    otpLoginCode: { type: String },
    phoneNumber: { type: String },
    onNumberChange: { type: Function },
    resend: { type: Function },
    submitOtp: { type: Function },
  },
  data() {
    return {
      error: "",
      countDown: 180,
      countDownShown: false,
    };
  },
  methods: {
    async login() {
      this.error = await this.submitOtp();
      
    },
    handleOnChange(e){
      this.onNumberChange(e);
    },
    handleOnComplete(e){
    //this.onNumberChange(e);
    },
    resendCode(){
      this.error = "";
      this.resend();
    },
    cancel(){
      this.$emit("cancelLogin");
    }
  },
};
</script>
