<template>
  <div class="payment-page-client-guest">
    <RequestHeader :formData="formData"></RequestHeader>
    <vs-row class="payments-page custom-mob-m">
      <input type="hidden" name="transaction_token" id="transaction_token" v-model="formData.transaction_token"/>
      <vs-col vs-w="8" vs-lg="8" vs-sm="12" vs-xs="12" class="m-0">

        <!-- Heading for payment request form -->
        <vx-card class="custom-vx-card mb-4 md:mb-6" v-if="paymentRequestDetail && paymentRequestDetail.requestType !=='recurring'">
          <h3 class="text-lg lg:text-xl font-normal card-title mb-4 md:mb-6 lg:mb-8">Payment options</h3>
          <div class="payment-page-option-radios w-full mb-2">
            <radio-box
              v-if="paymentRequestDetail && paymentRequestDetail.requestOptions && paymentRequestDetail.requestOptions.includes('pay-now')"
              title=" Pay now"
              name="customerType"
              radioValue="now"
              :description="payNowAmount"
              v-model="formData.payment_type"
              @change="savePaymentType"
              :disabled="showConfirmationForSuccess || showConfirmationForPaylaterSuccess">
            </radio-box>
            <radio-box
              v-for="(quote, key) in quote_data"
              :key="key"
              v-if="paymentRequestDetail && paymentRequestDetail.requestOptions && paymentRequestDetail.requestOptions.includes('pay-later')"
              :title="planDisplayName ? planDisplayName : quote.plan_name"
              name="customerType"
              :description="quote.planDescription ? quote.planDescription : quote.productText"
              v-model="formData.payment_type"
              :radioValue="quote.currentPlan._id"
              @change="savePaymentType"
              :disabled="
                showConfirmationForSuccess ||
                showConfirmationForPaylaterSuccess ||
                (idMatrix.verified == false && idMatrix.verifyCount === 3 && paymentRequestDetail.requestOptions && paymentRequestDetail.requestOptions.includes('pay-now'))"
            ></radio-box>
          </div>
        </vx-card>

        <vs-card
          class="accordin-card mb-4 md:mb-6"
          v-if="
            (formData.payment_type !== 'now' && (customFields.length > 0 || formData.attachments.length > 0)) ||
            (formData.payment_type === 'now' && (payNowAdditional || formData.attachments.length > 0))">
          <vs-collapse accordion>
            <vs-collapse-item class="additionalCard" id="customCollapse" ref="customCollapse" :open="additionalOpen">
              <p class="pt-2 px-4 sm:px-6 text-2xl" v-if="page_type === 'normal' && idMatrix.verifyCount === 2">ID check failed, Pay Later not available</p>
              <div slot="header">
                <h3 class="text-lg lg:text-xl font-normal card-title">Additional information</h3>
              </div>
              <div class="additionalContent pt-2 px-4 sm:px-6">
                <div class="flex valiadtion-popup mb-6" v-if="errors.items.length > 0">
                  <img :src="warningIcon"  class=" warning-icon"/>
                  <p class="mb-0 warningText">Please populate all mandatory fields before proceeding.</p>
                </div>
                <div class="mx-0 lg:mx-6" v-if="inputArray.length > 0 || dropdownArray.length>0">
                  <vs-row class="add-info-row md:grid md:gap-x-10 md:grid-cols-2">
                    <template v-for="(customField, key) in inputArrayFiltered">
                      <div
                        :key="key + 0.5"
                        class="add-info mb-6 lg:mb-8"
                        v-if="
                          customField.type !== 'address' &&
                          customField.type !== 'file' &&
                          (customField.editableByCustomer ||
                            customField.requiredToSubmit ||
                            (customField.displayToCustomer &&
                              Object.keys(customField.value.toString()).length > 0))">
                        <label style="color: #050540" :class="errors.has(`${customField.label}`) ? 'text-sm md:text-base text-danger' : 'text-sm md:text-base font-light'">
                          {{ customField.label }}
                          <span v-if="customField.requiredToSubmit" class="mid-blue">*</span>
                          <span v-if="customField.description">
                            <vs-tooltip :text="customField.description" position="top" class="icon-tooltip">
                              <img class="icon-control" :src="tooltip"  alt="tooltip" />
                            </vs-tooltip>
                          </span>
                        </label>

                        <vs-input
                          v-if="customField.type !== 'currency' && customField.type !== 'dropdown' && customField.type !== 'date' && (customField.editableByCustomer || customField.requiredToSubmit) "
                          v-validate="{
                            required: customField.requiredToSubmit ? true : false,
                            numeric: customField.type == 'number' ? true : false,
                          }"
                          :name="`${customField.label}`"
                          :type="customField.type"
                          v-model="customField.value"
                          @keypress="(customField.type == 'number')?isNumber($event):''"
                          :class="errors.has(`${customField.label}`) ? 'border-danger' : ''"
                          :disabled="!customField.editableByCustomer || (showConfirmationForSuccess || showConfirmationForPaylaterSuccess)"/>
                        <money
                          v-else-if="customField.type === 'currency' && (customField.editableByCustomer || customField.requiredToSubmit)"
                          v-validate="{customCurrency: customField.requiredToSubmit ? true : false}"
                          :name="`${customField.label}`"
                          v-model="customField.value"
                          :class="errors.has(`${customField.label}`) ? 'border-danger' : ''"
                          class="vs-input--input"
                          v-bind="moneyMask"
                          :disabled="(showConfirmationForSuccess || showConfirmationForPaylaterSuccess)"/>
                        <vs-select
                          v-validate="{required: customField.requiredToSubmit ? true : false}"
                          class="m-0 mob-w"
                          :class="errors.has(`${customField.label}`) ? 'border-danger selectExample w-full' : 'selectExample w-full'"
                          v-model="customField.value"
                          :name="`${customField.label}`"
                          v-else-if="customField.type === 'dropdown' && (customField.editableByCustomer || customField.requiredToSubmit)"
                          :disabled="(showConfirmationForSuccess || showConfirmationForPaylaterSuccess)">
                          <vs-select-item
                            :key="i"
                            :value="customField"
                            :text="customField"
                            v-for="(customField, i) in customField.dropdownSelectFields"/>
                        </vs-select>
                        <date-picker
                          v-else-if="customField.type === 'date' && (customField.editableByCustomer || customField.requiredToSubmit)"
                          v-validate="{ required: customField.requiredToSubmit ? true : false }"
                          :name="`${customField.label}`"
                          v-model="customField.value"
                          lang="en"
                          type="date"
                          placeholder="DD/MM/YYYY"
                          class="w-auto customdate"
                          input-class="date-picker-input"
                          format="DD/MM/YYYY">
                        </date-picker>
                        <p class="font-light leading-tight mt-2" v-else>
                          <span v-if="customField.type === 'currency'">$ {{ addZeroes(customField.value) }}</span>
                          <span v-else-if="customField.type === 'number'">{{ formatNumber(customField.value) }}</span>
                          <span v-else-if="customField.type === 'date'">{{ pfFormatDate(customField.value) }}</span>
                          <span v-else>{{ customField.value }}</span>
                        </p>
                        <span class="text-danger text-sm" v-show="errors.has(`${customField.label}`)">{{ errors.first(`${customField.label}`) }}</span>
                      </div>

                      <div
                        :key="key + 0.5"
                        class="add-info mb-6 lg:mb-8"
                        v-if="customField.type == 'file' && (customField.editableByCustomer || customField.requiredToSubmit || (customField.displayToCustomer && Object.keys(customField.value).length > 0))">
                        <label style="color: #050540" :class="errors.has(`${customField.label}`) ? 'text-sm md:text-base text-danger' : 'text-sm md:text-base font-light'">
                          {{ customField.label }}
                          <span v-if="customField.requiredToSubmit" class="mid-blue">*</span>
                          <span v-if="customField.description">
                            <vs-tooltip :text="customField.description" position="top" class="icon-tooltip">
                              <img class="icon-control" :src="tooltip"  alt="tooltip" />
                            </vs-tooltip>
                          </span>
                        </label>
                        <div class="addFile" v-if="showPreview(key)">
                          <S3FileGenerator class="text-sm" v-if="customField.value" :document="customField.value" :key="customField.value"/>
                          <a class="" v-if="customField.selectedFile">{{ customField.selectedFile.name }}</a>
                          <div class="fileAction" v-if="customField.editableByCustomer || customField.requiredToSubmit">
                            <svg
                              class="ml-auto"
                              width="20"
                              height="20"
                              viewBox="0 0 20 20"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                              @click="clearPreview(key, customField)">
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1.66675 4.99992C1.66675 4.53968 2.03984 4.16658 2.50008 4.16658H17.5001C17.9603 4.16658 18.3334 4.53968 18.3334 4.99992C18.3334 5.46016 17.9603 5.83325 17.5001 5.83325H2.50008C2.03984 5.83325 1.66675 5.46016 1.66675 4.99992Z"
                                fill="#757575"/>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.33342 2.49992C8.1124 2.49992 7.90044 2.58772 7.74416 2.744C7.58788 2.90028 7.50008 3.11224 7.50008 3.33325V4.16658H12.5001V3.33325C12.5001 3.11224 12.4123 2.90028 12.256 2.744C12.0997 2.58772 11.8878 2.49992 11.6667 2.49992H8.33342ZM14.1667 4.16658V3.33325C14.1667 2.67021 13.9034 2.03433 13.4345 1.56548C12.9657 1.09664 12.3298 0.833252 11.6667 0.833252H8.33342C7.67037 0.833252 7.03449 1.09664 6.56565 1.56548C6.09681 2.03433 5.83342 2.67021 5.83342 3.33325V4.16658H4.16675C3.70651 4.16658 3.33341 4.53968 3.33341 4.99992V16.6666C3.33341 17.3296 3.59681 17.9655 4.06565 18.4344C4.53449 18.9032 5.17037 19.1666 5.83342 19.1666H14.1667C14.8298 19.1666 15.4657 18.9032 15.9345 18.4344C16.4034 17.9655 16.6667 17.3296 16.6667 16.6666V4.99992C16.6667 4.53968 16.2937 4.16658 15.8334 4.16658H14.1667ZM5.00008 5.83325V16.6666C5.00008 16.8876 5.08788 17.0996 5.24416 17.2558C5.40044 17.4121 5.6124 17.4999 5.83342 17.4999H14.1667C14.3878 17.4999 14.5997 17.4121 14.756 17.2558C14.9123 17.0996 15.0001 16.8876 15.0001 16.6666V5.83325H5.00008Z"
                                fill="#757575"/>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M8.33342 8.33325C8.79365 8.33325 9.16675 8.70635 9.16675 9.16658V14.1666C9.16675 14.6268 8.79365 14.9999 8.33342 14.9999C7.87318 14.9999 7.50008 14.6268 7.50008 14.1666V9.16658C7.50008 8.70635 7.87318 8.33325 8.33342 8.33325Z"
                                fill="#757575"/>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M11.6667 8.33325C12.127 8.33325 12.5001 8.70635 12.5001 9.16658V14.1666C12.5001 14.6268 12.127 14.9999 11.6667 14.9999C11.2065 14.9999 10.8334 14.6268 10.8334 14.1666V9.16658C10.8334 8.70635 11.2065 8.33325 11.6667 8.33325Z"
                                fill="#757575"/>
                            </svg>
                          </div>
                        </div>
                        <input
                          type="file"
                          class="hidden"
                          v-validate="{ required: customField.requiredToSubmit && Object.keys(customField.value).length == 0 ? true : false, size: maxFileSize * 1024 }"
                          :name="customField.label"
                          accept=".pdf"
                          :ref="`uploadImgInputRef${key}`"
                          @change="updateCurrImg($event, key, customField)"/>
                        <div class="" v-if="!showPreview(key)">
                          <vs-button
                            v-if="customField.editableByCustomer"
                            v-round
                            class="only-border-btn mt-2"
                            size="small"
                            style="width: 126px"
                            @click="uploadImgInput(key)"
                            :disabled="(showConfirmationForSuccess || showConfirmationForPaylaterSuccess)">Upload file</vs-button>
                        </div>
                        <div class="text-danger text-sm" v-show="errors.has(`${customField.label}`)">
                          {{ errors.first(`${customField.label}`) }}
                        </div>
                      </div>
                      <div class="w-full col-span-2 mt-2" :key="key + 0.5" v-if="customField.type==='address' && (customField.editableByCustomer || customField.requiredToSubmit)">
                        <label
                          style="color: #050540"
                          :class="errors.has(`${customField.label}`) ? 'text-sm md:text-base text-danger' : 'text-sm md:text-base font-light'">
                          {{ customField.label }}
                          <span v-if="customField.requiredToSubmit" class="mid-blue">*</span>
                          <span v-if="customField.description">
                            <vs-tooltip :text="customField.description" position="top" class="icon-tooltip">
                              <img class="icon-control" :src="tooltip"  alt="tooltip" />
                            </vs-tooltip>
                          </span>
                        </label>
                        <vs-row class="mb-6">
                          <vs-col vs-w="12">
                            <vue-google-autocomplete
                              ref="address"
                              :name="`${customField.label}`"
                              style="left: unset"
                              :id="customField._id"
                              :value="productAddressFieldArray[customField._id].rawAddress"
                              classname="vs-input--input custom-w"
                              v-on:placechanged="getAddressForProductField"
                              :country="['au']"
                              placeholder="Start typing your address"
                              :disabled="(showConfirmationForSuccess || showConfirmationForPaylaterSuccess)">
                            </vue-google-autocomplete>
                          </vs-col>
                        </vs-row>
                        <vs-row class="mb-6">
                          <vs-col vs-w="12" class="pr-xs-0">
                            <label  style="color: #050540;" class="text-sm md:text-base font-light w-full">Apt, Suite, Unit, Building, Floor</label>
                            <vs-input
                              class="w-full custom-w mob-w"
                              v-model="productAddressFieldArray[customField._id].unit_number"
                              :name="`unit_number[${key}]`"
                              data-vv-as="unit number"
                              :disabled="(showConfirmationForSuccess || showConfirmationForPaylaterSuccess)"/>
                            <span class="text-danger text-sm" v-show="errors.has(`unit_number[${key}]`)">
                              {{ errors.first(`unit_number[${key}]`) }}
                            </span>
                          </vs-col>
                        </vs-row>
                        <vs-row class="mb-6">
                          <vs-col vs-w="12" class="">
                            <label style="color: #050540; width: auto" class="text-sm md:text-base font-light">Street address</label>
                            <div class="input-container">
                              <span class="text-danger text-sm" v-show="errors.has(`street_name[${key}]`)">{{ errors.first(`street_name[${key}]`) }}</span>
                              <vs-input
                                class="w-full custom-w add-street mob-w"
                                v-model="productAddressFieldArray[customField._id].street_name"
                                :name="`street_name[${key}]`"
                                v-validate="{required: customField.requiredToSubmit ? true : false}"
                                data-vv-as="street name"
                                :disabled="(showConfirmationForSuccess || showConfirmationForPaylaterSuccess)"/>
                            </div>
                          </vs-col>
                        </vs-row>
                        <div class="grid gap-x-4 md:gap-x-8 grid-cols-4 add-info-force mb-2">
                          <div class="mb-6 md:mb-8 suburbField col-span-4 md:col-span-2">
                            <label class="text-sm md:text-base font-light">Suburb</label>
                            <vs-input class="w-full custom-w mob-w-suburb" v-model="productAddressFieldArray[customField._id].suburb" :name="`suburb[${key}]`"
                              v-validate="{required: customField.requiredToSubmit ? true : false}"
                              data-vv-as="suburb"
                              :disabled="(showConfirmationForSuccess || showConfirmationForPaylaterSuccess)" />
                            <span class="text-danger text-sm" v-show="errors.has(`suburb[${key}]`)">{{ errors.first(`suburb[${key}]`) }}</span>
                          </div>
                          <div class="mb-6 md:mb-8 stateField col-span-2 md:col-span-1">
                            <label class="w-full text-sm md:text-base font-light">State</label>
                            <div class="input-container">
                              <vs-select
                                autocomplete
                                v-model="productAddressFieldArray[customField._id].state"
                                class="w-full custom-w"
                                :name="`state[${key}]`"
                                v-validate="{required: customField.requiredToSubmit ? true : false}"
                                placeholder="Select state"
                                data-vv-as="state"
                                :disabled="(showConfirmationForSuccess || showConfirmationForPaylaterSuccess)">
                                <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item, index) in states"></vs-select-item>
                              </vs-select>
                              <span class="text-danger text-sm" v-show="errors.has(`state[${key}]`)">
                                {{ errors.first(`state[${key}]`) }}
                              </span>
                            </div>
                          </div>

                          <div class="mb-6 md:mb-8 postcodeField col-span-2 md:col-span-1">
                            <label class="w-full text-sm md:text-base font-light">Postcode</label>
                            <div class="input-container">
                              <the-mask
                                class="w-full custom-w vs-input--input post-c"
                                type="number"
                                masked
                                :mask="['####']"
                                v-model="productAddressFieldArray[customField._id].postcode"
                                :name="`postcode[${key}]`"
                                v-validate="{required: customField.requiredToSubmit ? true : false}"
                                data-vv-as="post code"
                                :disabled="(showConfirmationForSuccess || showConfirmationForPaylaterSuccess)"
                              />
                              <span class="text-danger text-sm" v-show="errors.has(`postcode[${key}]`)">{{ errors.first(`postcode[${key}]`) }}</span>
                            </div>
                          </div>
                        </div>
                        <div class="mb-2" v-if="errors.has(`${customField.label}`)">
                          <span class="text-danger text-sm" v-show="errors.has(`${customField.label}`)">{{ errors.first(`${customField.label}`) }}</span>
                        </div>
                      </div>
                      <div class="mb-6 lg:mb-8" :key="key + 0.5" v-else-if="customField.type === 'address' && customField.displayToCustomer && Object.keys(customField.value).length > 0">
                        <label style="color: #050540" :class="errors.has(`${customField.label}`) ? 'text-sm md:text-base text-danger' : 'text-sm md:text-base font-light'">
                          {{ customField.label }}
                        </label>
                        <p class="font-light leading-tight mt-2">{{productAddressFieldArray[customField._id].rawAddress}}</p>
                      </div>
                    </template>
                  </vs-row>
                </div>
              </div>
              <div class="pb-6 md:pb-8 lg:pb-10 px-4 md:px-6" v-if="formData.attachments && formData.attachments.length > 0">
                <hr class="mt-2 mb-6 lg:mb-8 line-hr" />
                <h3 class="text-xl font-normal mb-4">Attached files</h3>
                <div class="">
                  <ul>
                    <li v-for="(file, key) in formData.attachments" :key="key" class="mb-2 ml-0 lg:ml-6">
                      <S3FileGenerator :document="file.name" :key="file.name"/>
                    </li>
                  </ul>
                </div>
              </div>
            </vs-collapse-item>
          </vs-collapse>
        </vs-card>

        <!--  More information for pay later -->
        <verify-id
          v-if="paymentRequestDetail.requestOptions.includes('pay-later')"
          :formData="formData"
          :idMatrix="idMatrix"
          :authorized_to_use="authorized_to_use"
          :showIdMatrixVerification="showIdMatrixVerification"
          :page_type="page_type"
          :states="states"
          :addressData="addressData"
          :checkCircle="checkCircle"
          :xCircle="xCircle"
          :index="index"
          :privacy="privacyLegals"
          :showConfirmationForSuccess="showConfirmationForSuccess"
          @loginIndex="loginIndex"
          @verifyId="verifyId"
          @idVerifyAuthorized="idVerifyAuthorized"
          @changeAddress="getAddressData"
          :payLaterLegals="payLaterLegals"
          :hide-id-error-block="hideIdErrorBlock"
        ></verify-id>

        <div
          class="verify-id-form mt-4 md:mt-6"
          v-if="
            (isDeviceVerified === true || index > 0) &&
            page_type === 'paylater' &&
            (formData.payment_type !== 'now' || (formData.payment_type === 'now' && idMatrix.verifyCount === 3))">
          <vx-card>
            <div class="card-title clearfix">
              <vs-col vs-w="9"><h5 class="text-lg lg:text-xl font-normal">Verify mobile number</h5></vs-col>
              <vs-col vs-w="3" class="text-right">
                <span class="taskDone"><img v-if="isDeviceVerified === true" :src="checkCircle" alt="Done"/></span>
              </vs-col>
            </div>
          </vx-card>
        </div>
        <div v-if="idMatrix.verified === true && index == 0">
          <vx-card class="verify-mobile-number" :class="page_type === 'paylater' && formData.payment_type !== 'now' ? 'mt-4 md:mt-6' : 'mt-4 md:mt-6 hiddenDiv'">
            <div class="verify-mob-pads">
              <div class="card-title mb-4">
                <h4 class="text-lg lg:text-xl font-normal">Verify mobile number</h4>
              </div>
              <p class="text-xs md:text-base leading-tight md:leading-normal font-light">
                Please enter an Australian mobile phone number. We'll send you a one-time code to verify it's yours.
              </p>
              <vs-row class="mt-0 mb-2 md:mb-6 md:mt-2 lg:w-auto justify-items-stretch md:justify-items-center md:justify-center w-full m-auto items-center md:items-start flex flex-col md:flex-row">
                <div class="mr-0 md:mr-6 lg:mr-8 mt-6 w-full md:w-1/2 flex flex-col full-input">
                  <the-mask
                    :class="formData.maskedMobileNumber ? 'hasValue phone-w' : 'phone-w'"
                    class="vs-input--input w-full"
                    :mask="['#### ### ###']"
                    v-model="formData.maskedMobileNumber"
                    data-vv-validate-on="blur"
                    masked
                    placeholder="Enter your mobile number"
                    name="mobile"
                    v-validate="{regex: /^04\(?\d{2}\)?[\s.-]\d{3}[\s.-]\d{3}$/,}"
                  />
                  <span class="text-danger text-xs md:text-sm mt-2 w-full" v-show="errors.has('mobile')">
                    {{ errors.first("mobile") }}
                  </span>
                </div>
                <div class="mt-6 text-center">
                  <vs-button
                    class="primary-btn send-icon only-border-btn mb-6 otp-button-pc"
                    v-round
                    size="small"
                    @click="sendVerificationCode"
                    v-if="isOtpDisabled"
                    :disabled="disableLoginOTP || formData.maskedMobileNumber === ''"
                    >
                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.1919 0.30806C14.436 0.552138 14.436 0.947866 14.1919 1.19194L7.31694 8.06694C7.07286 8.31102 6.67713 8.31102 6.43305 8.06694C6.18898 7.82287 6.18898 7.42714 6.43305 7.18306L13.3081 0.30806C13.5521 0.0639827 13.9479 0.0639827 14.1919 0.30806Z"
                        fill="#0F67F4"
                      />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.1919 0.30806C14.3617 0.477834 14.4192 0.729854 14.3399 0.956471L9.96491 13.4565C9.88025 13.6983 9.65652 13.864 9.40047 13.8745C9.14442 13.8849 8.90794 13.738 8.80386 13.5038L6.40149 8.09851L0.996159 5.69614C0.761984 5.59206 0.615069 5.35558 0.625515 5.09953C0.635961 4.84348 0.801651 4.61975 1.04353 4.53509L13.5435 0.160091C13.7701 0.0807748 14.0222 0.138287 14.1919 0.30806ZM2.94442 5.19413L7.12883 7.05387C7.27023 7.11671 7.38329 7.22977 7.44613 7.37117L9.30587 11.5556L12.7313 1.76873L2.94442 5.19413Z"
                        fill="#0F67F4"
                      />
                    </svg>
                    Send Code
                  </vs-button>
                  <vs-button
                    class="primary-btn send-icon only-border-btn mb-6 otp-button-mobile"
                    v-round
                    size="small"
                    @click="sendVerificationCode"
                    v-if="isOtpDisabled"
                    :disabled="disableLoginOTP || formData.maskedMobileNumber === ''"
                    >
                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.1919 0.30806C14.436 0.552138 14.436 0.947866 14.1919 1.19194L7.31694 8.06694C7.07286 8.31102 6.67713 8.31102 6.43305 8.06694C6.18898 7.82287 6.18898 7.42714 6.43305 7.18306L13.3081 0.30806C13.5521 0.0639827 13.9479 0.0639827 14.1919 0.30806Z"
                        fill="#0F67F4"
                        />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.1919 0.30806C14.3617 0.477834 14.4192 0.729854 14.3399 0.956471L9.96491 13.4565C9.88025 13.6983 9.65652 13.864 9.40047 13.8745C9.14442 13.8849 8.90794 13.738 8.80386 13.5038L6.40149 8.09851L0.996159 5.69614C0.761984 5.59206 0.615069 5.35558 0.625515 5.09953C0.635961 4.84348 0.801651 4.61975 1.04353 4.53509L13.5435 0.160091C13.7701 0.0807748 14.0222 0.138287 14.1919 0.30806ZM2.94442 5.19413L7.12883 7.05387C7.27023 7.11671 7.38329 7.22977 7.44613 7.37117L9.30587 11.5556L12.7313 1.76873L2.94442 5.19413Z"
                        fill="#0F67F4"
                        />
                    </svg>

                    Send verification code
                  </vs-button>
                  <vs-button
                    style="width: 178px"
                    class="send-icon only-border-btn"
                    size="small"
                    v-round
                    @click="sendVerificationCode"
                    v-else
                    :disabled="disableLoginOTP || errors.has('mobile') || formData.maskedMobileNumber === ''">
                    <svg width="15" height="14" viewBox="0 0 15 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.1919 0.30806C14.436 0.552138 14.436 0.947866 14.1919 1.19194L7.31694 8.06694C7.07286 8.31102 6.67713 8.31102 6.43305 8.06694C6.18898 7.82287 6.18898 7.42714 6.43305 7.18306L13.3081 0.30806C13.5521 0.0639827 13.9479 0.0639827 14.1919 0.30806Z"
                        fill="#0F67F4"
                        />
                      <path
                        fill-rule="evenodd"
                        clip-rule="evenodd"
                        d="M14.1919 0.30806C14.3617 0.477834 14.4192 0.729854 14.3399 0.956471L9.96491 13.4565C9.88025 13.6983 9.65652 13.864 9.40047 13.8745C9.14442 13.8849 8.90794 13.738 8.80386 13.5038L6.40149 8.09851L0.996159 5.69614C0.761984 5.59206 0.615069 5.35558 0.625515 5.09953C0.635961 4.84348 0.801651 4.61975 1.04353 4.53509L13.5435 0.160091C13.7701 0.0807748 14.0222 0.138287 14.1919 0.30806ZM2.94442 5.19413L7.12883 7.05387C7.27023 7.11671 7.38329 7.22977 7.44613 7.37117L9.30587 11.5556L12.7313 1.76873L2.94442 5.19413Z"
                        fill="#0F67F4"
                        />
                    </svg>
                    Re-send Code
                  </vs-button>
                </div>
              </vs-row>
            </div>
            <div class="verify-mob-pads bg-grey-background" v-if="!isOtpDisabled">
              <vs-row vs-justify="center">
                <span class="text-sm md:text-base mt-2 md:mt-0 font-normal text-dark-blue text-center">Please enter the 6-digit code we just sent to {{ formData.maskedMobileNumber }}.</span>
              </vs-row>

              <vs-row class="mt-6 pt-0 md:pt-1 pb-2 otp-align" vs-justify="center">
                <v-otp-input ref="otpInput" :disabled="isOtpDisabled" :class="{ 'disabled-otp': isOtpDisabled, 'invalidBox': isOtpInvalid }" input-classes="otp-input" separator=""
                  :num-inputs="6" :should-auto-focus="true" :is-input-num="true" @on-change="handleOnChange" @on-complete="handleOnComplete"/>
              </vs-row>

              <vs-row vs-justify="center">
                <div class="">
                  <p v-if="isOtpInvalid" class="width-break text-xs leading-tight text-center font-normal mb-1 md:mb-2 text-red">Verification code invaild. Please try again.</p>
                  <p v-else class="width-break text-xs leading-tight text-center font-normal mb-1 md:mb-2">Didn't get a code? Press 'Re-send code' button above to get a new code.</p>
                </div>
              </vs-row>

              <vs-row vs-justify="center" class="pt-5 pb-0 md:pb-4">
                <vs-button
                  style="max-width: 590px"
                  size="large"
                  class="lg:w-10/12 md:w-10/12 w-full"
                  v-round
                  :disabled="!isOtpComplete"
                  @click="verifyMobile">
                  Verify mobile number
                </vs-button>
              </vs-row>
            </div>
          </vx-card>
        </div>

        <!--------Verify Email-------->
        <verify-email :formData="formData" :idMatrix="idMatrix" :page_type="page_type" :checkCircle="checkCircle" :index="index" :isEmailVerified="isEmailVerified"
          :isEmailOtpInvalid="isEmailOtpInvalid" :isEmailOtpDisabled="isEmailOtpDisabled" @sendCode="sendEmailVerificationCode" @verifyEmail="verifyEmail"></verify-email>
        <!--------Verify Email-------->

        <!-- Show payment options and cards -->
        <vx-card v-if="hideCardWhenSuccess" :class="showPaymentOptions && showDirectPayNowFromApiConfig && !hidePayNow ? '' : 'hiddenDiv'" id="payment-card-old">
          <div class="ds-wrapper">
            <vs-row class="bg-container-60 rounded-2xl md-rounded-3xl" vs-w="12" vs-align="flex-start" vs-type="flex" vs-justify="center">
              <vs-col vs-md="12" vs-sm="12">
                <div class="card-title mb-4">
                  <h2 class="text-lg lg:text-xl font-normal text-dark-blue">{{ paymentMethodDescription ? paymentMethodDescription.paymentSectionHeading : "Payment method" }}</h2>
                  <p class="mt-8" v-if="paymentMethodDescription && paymentMethodDescription.paymentMethodDescription">
                    {{ paymentMethodDescription.paymentMethodDescription }}
                  </p>
                </div>
              </vs-col>
              <vs-col vs-md="6" vs-sm="12" class="max-w-420" v-if="['CARD', 'DIRECT_DEBIT'].includes(tabIndex)">
                <div class="flex flex-row justify-center gap-4">
                  <div class="w-1/2 md:w-2/5" @click="selectTab('CARD')" v-if="paymentMethods.includes('CARD')">
                    <vs-chip :class="`w-full tabs-chip ${ tabIndex == 'CARD' ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline">
                      <CardPayIcon class="chip-icon" />
                      <div class="chip-text">Card</div>
                    </vs-chip>
                  </div>
                  <div class="w-1/2 md:w-2/5" @click="selectTab('DIRECT_DEBIT')" v-if="paymentMethods.includes('DIRECT_DEBIT')">
                    <vs-chip :class="`w-full tabs-chip ${ tabIndex == 'DIRECT_DEBIT' ? 'chip-active' : '' }`" color="neutral-150" size="large" variant="outlined" type="multiline">
                      <DirectDebitIcon class="chip-icon" />
                      <div class="chip-text">Direct debit</div>
                    </vs-chip>
                  </div>
                </div>
                <vs-divider class="my-8" />
                <div class="flex flex-col gap-y-8 mb-8" v-if="tabIndex == 'CARD'">
                  <div class="flex md:flex-row flex-wrap justify-center gap-x-3 gap-y-2">
                    <div v-for="card_option in card_options" :key="card_option._id">
                    <div class="text-center" :class="cardType === card_option.card.slug || cardType === 'default' ? '' : 'opacity-30'">
                      <img v-if="card_option.card.slug == 'visa'" :src="visaCard" alt="visa" />
                      <img v-else-if="card_option.card.slug == 'mastercard'" :src="masterCard" alt="masterCard" />
                      <img v-else-if="card_option.card.slug == 'amex'" :src="amexCard" alt="amex" />
                      <img v-else-if="card_option.card.slug == 'diners'" :src="dinersClubCard" alt="diners-club" />
                      <img v-else-if="card_option.card.slug == 'unionpay'" :src="unionpayCard" alt="unionpay" />
                      <div class="text-xs font-light leading-tight"  v-if="!SurChargable">{{ addZeroes(card_option.surcharge) }}%</div>
                    </div>
                    </div>
                  </div>
                  <div class="w-full">
                    <vs-input name="cardHolder" label="Name on card" v-model="formData.name" :success="cardNameValid" :class="errors.has('cardHolder') ? 'hasError' : ''" icon="done" icon-after="true" icon-size="small" v-validate="'required|cardNameFormat'" data-vv-as="name on card" @change="checkFieldValidation('cardName')" />
                    <span class="span-text-validation-danger" v-if="errors.has('cardHolder')"><span class="span-text-validation">{{ errors.first("cardHolder") }}</span></span>
                  </div>
                  <div class="w-full">
                    <div class="custom-input-field" :class="[!cardValid || !isCardAllowed || showCardNumberIsRequired ? 'hasError' : '', cardFocused ? 'isFocused' : '', cardSuccess ? 'isValid' : '']" >
                      <label for="number_div" class="input-label">Card number</label>
                      <div id="number_div" style="height: 28px"></div>
                      <vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" />
                    </div>
                    <span class="span-text-validation-danger" v-if="!isCardAllowed && !showCardNumberIsRequired"><span class="span-text-validation">This card type is not supported. Please try another card</span></span>
                    <span class="span-text-validation-danger" v-if="showCardNumberIsRequired"><span class="span-text-validation">Card number is required</span></span>
                    <span class="span-text-validation-danger" v-if="!cardValid && !showCardNumberIsRequired && isCardAllowed"><span class="span-text-validation">Invalid card number</span></span>
                  </div>
                  <div class="flex flex-row gap-x-6">
                    <div class="w-1/2">
                      <vs-input class="input-arial" :class="showExpDateIsRequired || showExpired ? 'hasError' : ''" label="Expiry date" placeholder="MM/YY" v-mask="'##/##'" v-model="formData.card_expiry_date" :success="dateSuccess" icon="done" icon-after="true" icon-size="small" @keyup.native="handleExpiryCardField" @blur="handleExpiryCardFieldOnBlur" />
                      <span class="span-text-validation-danger" v-if="showExpDateIsRequired"><span class="span-text-validation">Card expiry date is required</span></span>
                      <span class="span-text-validation-danger" v-else-if="showExpired"><span class="span-text-validation">Expiry date is invalid</span></span>
                    </div>
                    <div class="w-1/2">
                      <div class="custom-input-field" :class="[securityFocused ? 'isFocused' : '', showCvcIsRequired ? 'hasError' : '', cvvSuccess ? 'isValid' : '', isInvalidCvv ? 'hasError' : '' ]">
                        <label class="input-label">
                          Security code
                          <popper trigger="hover" :options="{ placement: 'top', modifiers: { offset: { offset: '-55px, 2px' }}}">
                            <template slot="reference" class="top"><ToolTipIcon width="12px" height="12px" class="vs-icon" /></template>
                            <div class="popper py-2 px-4 text-center" v-if="cardType == 'amex'">
                              <p class="text-primary">The 4 digits on the front of your card.</p>
                              <div class="flex flex-row gap-x-4">
                                <div><img :src="amex1" alt="" /></div>
                              </div>
                            </div>
                            <div class="popper py-2 px-4" v-else-if="['visa', 'mastercard'].includes(cardType)">
                              <p class="text-primary">The 3 digits on the back of your card.</p>
                              <div class="flex flex-row gap-x-4">
                                <div><img :src="general1" alt="" /></div>
                              </div>
                            </div>
                            <div class="popper py-2 px-4 text-center" v-else>
                              <p>For American Express (left), the 4 digits on the front of your card.</p>
                              <p>For all other cards (right), the 3 digits on the back of your card.</p>
                              <div class="flex flex-row pt-2 gap-x-4">
                                <div><img :src="amex" alt="" /></div>
                                <div><img :src="general" alt="" /></div>
                              </div>
                            </div>
                          </popper>
                        </label>
                        <div id="cvv_div" style="height: 28px"></div>
                        <vs-icon class="validation-icon" icon="done" icon-after="true" icon-size="small" />
                      </div>
                      <span class="span-text-validation-danger" v-if="showCvcIsRequired"><span class="span-text-validation">Security code is required</span></span>
                      <span class="span-text-validation-danger" v-else-if="isInvalidCvv"><span class="span-text-validation">Security code is invalid</span></span>
                    </div>
                  </div>
                  <div class="flex flex-row items-start mt-6">
                    <vs-checkbox v-model="payNowAcceptance" name="payNowAcceptance"> </vs-checkbox>
                    <p class="ancillary-regular cursor-pointer" @click="acceptPayNowTerms" v-html="payNowLegals.explicitTerms"></p>
                  </div>
                  <div v-if="page_type === 'paylater' && formData.payment_type !== 'now'" class="flex flex-row items-start mt-2 mb-5">
                    <vs-checkbox  v-model="toc_acceptance" name="toc_acceptance"> </vs-checkbox>
                    <p class="ancillary-regular cursor-pointer" @click="acceptTerms" v-html="payLaterLegals.explicitTerms"></p>
                  </div>
                  <div v-if="showPayNow && showDirectPayNowFromApiConfig && !hidePayNow">
                    <vs-button color="primary" class="w-full mb-4" size="large" @click="submitNormalOrRecurringPayment" :disabled="handlePayNowDisable">{{ page_type === "recurring" ? "Register Payment Method" : `Pay $${addZeroes(total)} now` }}</vs-button>
                  </div>
                  <div v-if="page_type === 'paylater' && formData.payment_type !== 'now'">
                    <vs-button v-if="productType == 'B2C_BNPL'" color="primary" class="w-full mb-4" size="large" @click="submitPayLaterPayment" :disabled="handlePayLaterDisable">Accept plan & pay ${{ addZeroes(formData.selectedPaymentPlan.perInstalmentAmount) }}</vs-button>
                    <vs-button v-else color="primary" class="w-full mb-4" size="large" @click="submitPayLaterPayment" :disabled="handlePayLaterDisable">Accept plan</vs-button>
                  </div>
                </div>
                <div class="flex flex-col gap-y-8 mb-8" v-if="tabIndex == 'DIRECT_DEBIT'">
                  <div class="w-full">
                    <vs-input name="accountName" label="Account name" v-model="formData.accountName" :success="accountNameValid" :class="errors.has('accountName') ? 'hasError' : ''" icon="done" icon-after="true" icon-size="small" v-validate="'required'" data-vv-as="account name" @change="checkFieldValidation('accountName')" />
                    <span class="span-text-validation-danger" v-if="errors.has('accountName')"><span class="span-text-validation">{{ errors.first("accountName") }}</span></span>
                  </div>
                  <div class="flex flex-row gap-x-6">
                    <div class="w-2/5">
                      <vs-input
                        label="BSB"
                        name="bsb"
                        :class="!bsbError ? 'hasError' : ''"
                        placeholder="000 - 000"
                        v-mask="'###-###'"
                        v-model="formData.bsb"
                        :success="bsbSuccess"
                        icon="done"
                        icon-after="true"
                        icon-size="small"
                        v-validate="'required'"
                        @blur="handleBsb"
                      />
                      <span class="span-text-validation-danger" v-if="errors.has('bsb')"><span class="span-text-validation">{{ errors.first("bsb") }}</span></span>
                      <span class="span-text-validation-danger" v-if="!showBsbValid"><span class="span-text-validation">Invalid BSB</span></span>
                    </div>
                    <div class="w-3/5">
                      <vs-input name="accountNumber" label="Account number" v-model="formData.accountNumber" :success="accountNumberValid" :class="errors.has('accountNumber') ? 'hasError' : ''" icon="done" icon-after="true" icon-size="small" v-validate="'required|alphanumFormat'" data-vv-as="account number" @change="checkFieldValidation('accountNumber')" />
                      <span class="span-text-validation-danger" v-if="errors.has('accountNumber')"><span class="span-text-validation">{{ errors.first("accountNumber") }}</span></span>
                    </div>
                  </div>
                  <div class="flex flex-row items-start mt-6">
                    <vs-checkbox v-model="toc_acceptance_direct_debit" name="toc_acceptance_direct_debit"> </vs-checkbox>
                    <p class="ancillary-regular cursor-pointer" @click="acceptTermsNPP" v-html="directDebitLegals.explicitTerms"></p>
                  </div>
                  <div class="flex flex-row items-start mb-5">
                    <vs-checkbox v-model="toc_acceptance" name="toc_acceptance"> </vs-checkbox>
                    <p class="ancillary-regular cursor-pointer" @click="acceptTerms" v-html="payLaterLegals.explicitTerms"></p>
                  </div>
                  <div>
                    <vs-button v-if="productType == 'B2C_BNPL'" color="primary" class="w-full mb-4" size="large" @click="submitPayLaterPayment"
                    :disabled="handlePayLaterDisable">Accept plan & pay ${{ addZeroes(formData.selectedPaymentPlan.perInstalmentAmount) }}</vs-button>
                    <vs-button v-else color="primary" class="w-full mb-4" size="large" @click="submitPayLaterPayment" :disabled="handlePayLaterDisable">Accept plan</vs-button>
                  </div>
                </div>
              </vs-col>
              <vs-col vs-md="12" v-else>
                <div class="flex flex-col gap-y-8 mb-8 mt-4" v-if="paymentMethods.length == 1 && paymentMethods.includes('EFT')">
                  <div class="flex flex-row items-start mb-5">
                    <vs-checkbox v-model="toc_acceptance" name="toc_acceptance"> </vs-checkbox>
                    <p class="ancillary-regular cursor-pointer" @click="acceptTerms" v-html="payLaterLegals.explicitTerms"></p>
                  </div>
                  <div>
                    <vs-button color="primary" class="w-full mb-4" size="large" @click="submitPayLaterPayment" :disabled="handlePayLaterDisable">Accept plan</vs-button>
                  </div>
                </div>
              </vs-col>
            </vs-row>
          </div>
        </vx-card>

        <!-- Transaction status for one off and confirmation -->
        <vx-card class="mt-4 md:mt-6 transaction-confirm-card" v-if="showConfirmationForSuccess">
          <div class="card-title">
            <h3 class="text-lg lg:text-xl font-normal">Transaction confirmation</h3>
          </div>
          <div class="mx-4">
            <div class="mx-auto mb-6 md:mb-8" style="border: 1px solid #219653; border-radius: 8px; max-width: 353px;" >
              <vs-alert active="true" color="success" class="trans-success h-auto">
                <span class="alert-icon">
                  <img :src="checkCircle" alt="Done" />
                </span>
                <div class="flex flex-col mx-4">
                  <h3 class="font-normal text-base mb-2">Payment successful</h3>
                  <p class="text-xs font-normal leading-normal">
                    Your transaction reference number is {{ till_response_uuid }}.
                  </p>
                </div>
              </vs-alert>
            </div>
          </div>
          <vs-row class="pb-6 lg:pb-8 send-confirm-section">
            <vs-col vs-w="12" class="text-center">
              <h4 class="text-lg md:text-xl font-normal mb-4 md:mb-6">Send confirmation</h4>
              <p class="text-base px-8 md:px-10 lg:px-24 font-light">Enter your mobile number or email address and we'll send you a confirmation for this payment.</p>
            </vs-col>
            <div class="mt-0 mb-2 md:mb-4 justify-items-stretch md:justify-items-center md:justify-center w-full m-auto items-center md:items-start flex flex-col md:flex-row">
              <div class="ph-phone mr-0 md:mr-6 lg:mr-8 mt-6 w-full md:w-1/2 flex flex-col">
                <div class="relative">
                  <vs-input class="w-full full-input" type="text" v-model="formData.sendConfirmText" name="sendConfirmText" placeholder="Enter email address or mobile number" />
                  <span class="text-danger text-xs md:text-sm mt-2 block leading-tight" v-show="sendConfirmTextError">{{ sendConfirmTextError }}</span>
                </div>
              </div>
              <div class="mt-6">
                <vs-button class="w-auto px-8 small only-border-btn" v-round :disabled="!formData.sendConfirmText" @click="sendConfirmationForTransaction">Send</vs-button>
              </div>
            </div>
          </vs-row>
        </vx-card>

        <!-- Transaction status for pay later -->
        <vx-card class="mt-6 lg:mt-8" v-if="showConfirmationForPaylaterSuccess">
          <div class="card-title mb-6 md:mb-8 lg:mb-10">
            <h3 class="text-lg md:text-xl font-normal">Transaction confirmation</h3>
          </div>
          <vs-row>
            <vs-col vs-w="12">
              <div class="mx-auto mb-6 md:mb-8" style="border: 1px solid #219653; border-radius: 8px; max-width: 353px !important;">
                <vs-alert active="true" color="success" class="trans-success h-auto">
                  <span class="alert-icon"><img :src="checkCircle" alt="Done" /></span>
                  <div class="flex flex-col px-4">
                    <h3 class="font-normal text-base mb-2">All done!</h3>
                    <p class="text-xs font-normal leading-normal">Your transaction reference is : {{ paymentRID }}</p>
                  </div>
                </vs-alert>
              </div>
            </vs-col>
          </vs-row>
        </vx-card>

        <!-- Transaction status for pay later failure -->
        <vx-card class="mt-5 p-3" v-if="formData.payment_type !== 'now' && showConfirmationForPayLaterError && till_failure_id_later">
          <div class="card-title mb-6 lg:mb-8">
            <strong class="text-base">Transaction confirmation</strong>
          </div>
          <p><span class="text-red">Failed</span> ({{ failureReason }},reference number: {{ till_failure_id }})</p>
          <p>Scroll up to check your payment details or try another method.</p>
          <p>We can't start your payment plan without the first payment successfully completing.</p>
        </vx-card>

        <SuccessUrl v-if="showSuccessBox" :redirectData="redirectData"></SuccessUrl>

        <FailureUrl v-if="idMatrix.verified == false && idMatrix.verifyCount > 2 && formData.payment_type !== 'now'" :redirectData="redirectData"></FailureUrl>
        <vs-row class="mt-10 md:mt-4">
          <vs-col vs-w="12" class="flex justify-end">
            <div class="poweredBy">
              <span class="text-xxs font-normal">Powered by</span>
              <img v-if="portal === 'FlipPay'" src="@/assets/images/logo/flippay-logo.svg" alt="flipPay logo"/>
              <img v-else src="@/assets/images/logo/rellopay-logo.svg" alt="relloPay logo"/>
            </div>
          </vs-col>
        </vs-row>
      </vs-col>

      <vs-col vs-w="3" vs-lg="4" vs-md="6" vs-sm="12" vs-xs="12" class="m-0 mob-view pl-0 md:pl-4 lg:pl-6">
        <payment-summary
          v-if="paymentBind"
          :paymentRequestDetail="paymentRequestDetail"
          :card_status="card_status"
          :card_options="card_options"
          :paymentTypeForSummary="paymentTypeForSummary"
          :prTransactionSchedule="prTransactionSchedule"
          v-on:changeTotal="updateTotal($event)">
        </payment-summary>
      </vs-col>
    </vs-row>
    <div class="mobile-footer">
      <the-footer v-if="footerBind" :pageBranding="paymentRequestDetail.paymentPage && paymentRequestDetail.paymentPage.pageBranding ? paymentRequestDetail.paymentPage.pageBranding : ''" />
    </div>
  </div>
</template>

<script>

import { mapActions } from "vuex";
import moment from "moment";
import DatePicker from "vue2-datepicker";
import { TheMask } from "vue-the-mask";
import { RRule } from "rrule";
import { Validator } from "vee-validate";
import RequestHeader from "./components/requestHeader";
import SuccessUrl from "./components/successUrl.vue";
import FailureUrl from "./components/failureUrl.vue";
import RadioBox from "@/views/components/radiobox";
import CurrencyInput from "@/views/components/Input";
import VueGoogleAutocomplete from "vue-google-autocomplete";
import PaymentSummary from "./components/paymentSummary.vue";
import VerifyId from "./verifyId.vue";
import OTP from "./otp.vue";
import TheFooter from "@/layouts/guest-components/TheFooter";
import VerifyEmail from "./verifyEmail.vue";
import { Money } from "v-money";
import S3ImageGenerator from "@/views/components/S3ImageGenerator";
import S3FileGenerator from "@/views/components/S3FileGenerator";
import { sentryLog } from "../../helper/sentryLog";

// New design system start
import CardPayIcon from "../components/design-sys-components/icons/CardPayIcon";
import DirectDebitIcon from "../components/design-sys-components/icons/DirectDebitIcon";
import ToolTipIcon from "../components/design-sys-components/icons/ToolTipIcon";
// Third party import
import { mask } from 'vue-the-mask';
import Popper from 'vue-popperjs';
// Credit Cards
const visaCard = require("@/assets/images/cards/visa.svg");
const masterCard = require("@/assets/images/cards/mastercard.svg");
const amexCard = require("@/assets/images/cards/amex.svg");
// Tooltip images
const amex = require("@/assets/images/cards/amex.png");
const amex1 = require("@/assets/images/cards/amex-1.png");
const general = require("@/assets/images/cards/general.png");
const general1 = require("@/assets/images/cards/general-1.png");
const dinersClubCard = require("@/assets/images/cards/dinersclub.svg");
const unionpayCard = require("@/assets/images/cards/unionpay.svg");
const directDebitCard = require("@/assets/images/cards/directdebit.svg");
// New design system end

const cardImg = require("@/assets/images/card-pic.png");
const tooltip = require("@/assets/images/tooltip_dark.svg");
const dictionary = {
  en: {
    attributes: {
      mobile: "mobile phone",
      street_address: "street address",
      unit_number: "unit number",
      street_number: "street number",
      street_name: "street name",
      street_type: "street type",
      exp_month: "expiry month",
      exp_year: "expiry year",
      exp_date: "expiry date",
      first_name: "first name",
      last_name: "last name",
      date_of_birth: "date of birth",
      license_number: "licence number",
      license_expiry_date: "licence expiry date",
      license_state: "licence state",
      license_card_number: "licence card number",
      passport_number: "passport number",
      passport_issue_date: "passport issue date",
      passport_expiry_date: "passport expiry date",
    },
    messages: {
      regex: () => "Phone number should start with 04 and should be 10 digits",
      expiryDate: () => "Please enter a valid Expiry Number",
    },
  },
};

Validator.extend("expiryDate", {
  getMessage: () => "Date is not valid",
  validate: (value) => {
    const regexValue = /^(0[1-9]|1[0-2])\/\d{2}$/.test(value);
    const date = moment(value, "MM-YY");
    if (date < moment()) {
      return false;
    }
    return regexValue;
  },
});

Validator.localize(dictionary);

export default {
  components: {
    S3FileGenerator,
    S3ImageGenerator,
    RadioBox,
    CurrencyInput,
    DatePicker,
    TheMask,
    VueGoogleAutocomplete,
    RequestHeader,
    SuccessUrl,
    FailureUrl,
    PaymentSummary,
    VerifyId,
    OTP,
    TheFooter,
    VerifyEmail,
    Money,
    Popper,
    CardPayIcon,
    DirectDebitIcon,
    ToolTipIcon,
  },
  data() {
    return {
      tooltip:tooltip,
      productAddressFieldArray: {},
      productAddressField: {
        rawAddress: "",
        suburb: "",
        state: "",
        unit_number: "",
        street_number: "",
        street_name: "",
        street_type: "",
      },
      moneyMask: {
        thousands: ",",
        prefix: "$ ",
        suffix: "",
        precision: 2,
        masked: false,
      },
      cardsForPaylater: [],
      paymentResponseData: {},
      disableSendConfirmButton: false,
      showCardInputBox: true,
      showNameIsRequired: false,
      showExpDateIsRequired: false,
      showExpired: false,
      showCardNumberIsRequired: false,
      showCvcIsRequired: false,
      redirectData: {},
      showSuccessBox: false,
      hideCardWhenSuccess: true,
      paymentBind: false,
      paymentTypeForSummary: "",
      cardImg: cardImg,
      isOtpInvalid: false,
      isOtpComplete: false,
      paymentRequestDetail: {},
      page_type: "normal",
      baseUrl: process.env.VUE_APP_API_URL,
      customerPortalUrl: process.env.VUE_APP_CUSTOMER_PORTAL_URL,
      portal: process.env.VUE_APP_NAME,
      checkCircle: require("@/assets/images/icons/check-circle.png"),
      xCircle: require("@/assets/images/icons/x-circle.svg"),
      visa: require("@/assets/images/icons/visa.svg"),
      amex: require("@/assets/images/icons/amex.svg"),
      unionPay: require("@/assets/images/icons/unionpay.svg"),
      mastercard: require("@/assets/images/icons/mastercard.svg"),
      warningIcon: require("@/assets/images/icons/alert-circle.svg"),
      appUrl: process.env.VUE_APP_ROOT_API,
      addressData: "",
      rawAddress : "",
      formData: {
        streetNameOnly : "",
        sendConfirmText: "",
        scheduleText: "",
        paymentRequestId: "",
        amount: 0,
        reference: "",
        mobile: "",
        maskedMobileNumber: "",
        email: "",
        name: "",
        street_address: "",
        suburb: "",
        state: "",
        postcode: "",
        exp_month: "",
        exp_year: "",
        exp_date: "",
        card_expiry_date: "",
        transaction_token: "",
        transaction_data: "",
        message: "",
        payment_request_from: {
          name: "",
          phone_number: "",
          email: "",
        },
        attachments: "",
        periodLength: 0,
        payment_type: "now",
        payment_method_type: "card",
        address_search: "",
        unit_number: "",
        street_number: "",
        street_name: "",
        street_type: "",
        id_type: "DL",
        first_name: "",
        last_name: "",
        middle_name: "",
        date_of_birth: "",
        licenseDetails: {
          license_number: "",
          expiry_date: "",
          state: "",
          card_number: "",
        },
        passportDetails: {
          passport_number: "",
          issue_date: "",
          expiry_date: "",
        },
        verification_code: "",
        confirm_email: "",
        payment_mode: "now",
        selectedPaymentPlan: {},
        paymentUUID: "",
        customerId: "",
        email_verification_code: "",
        commsPreference: {
          email: false,
          sms: false,
        },
        accountName: "",
        bsb: "",
        accountNumber: "",
        customerSelectedPaymentMethod: "CARD",
      },
      productType: "",
      total: "",
      customerDetails: {},
      captchaVerified: false,
      wpwlOptions: { style: "plain" },
      paymentJSObject: undefined,
      todayDate: new Date(),
      companyHeader: "",
      companyDescription: "",
      companyLogo: "",
      card_status: {},
      card_options: [],
      till_public_key: "",
      uploadedUrl: process.env.VUE_APP_API_URL + "uploads/icons/",
      cardWithSurcharge: true,
      isCardAllowed: true,
      paymentRequestFrequency: {},
      paymentSchedules: [],
      prTransactionSchedule: [],
      isRecurring: false,
      totalNumberOfPayments: 0,
      scheduleDescription: "",
      scheduleDescriptionArray: [],
      firstPayment: "",
      customFields: [],
      productCustomFields: [],
      defaultCustomFields: [],
      productCustomFieldsNotViewable: [],
      dropdownArray: [],
      inputArray: [],
      inputArrayFiltered: [],
      monthly_quote_data: {},
      fortnightly_quote_data: {},
      quote_data: [],
      states: [
        { text: "NSW", value: "NSW" },
        { text: "ACT", value: "ACT" },
        { text: "QLD", value: "QLD" },
        { text: "VIC", value: "VIC" },
        { text: "NT", value: "NT" },
        { text: "SA", value: "SA" },
        { text: "WA", value: "WA" },
        { text: "TAS", value: "TAS" },
      ],
      authorized_to_use: false,
      toc_acceptance: false,
      toc_acceptance_direct_debit: false,
      payNowAcceptance: false,
      privacy_acceptance: false,
      showConfirmationForSuccess: false,
      planDisplayName: "",
      showConfimationForError: false,
      payNowConfirmationError: false,
      payLaterConfirmationError: false,
      showConfirmationForPaylaterSuccess: false,
      showConfirmationForPayLaterError: false,
      showMobileVerification: false,
      showPayNow: true,
      showPaymentPlan: false,
      showPaymentOptions: true,
      till_response_uuid: "",
      disablePayNow: false,
      disablePayLater: false,
      showAcceptAgreement: false,
      disableDeviceVerification: false,
      payment_plans: [],
      isOtpDisabled: true,
      appUrl: process.env.VUE_APP_ROOT_API,
      isEmailOtpDisabled: true,
      idMatrix: {
        enquiryId: "",
        verified: null,
        verifyCount: 0,
      },
      failureReason: "",
      showVerifyMobile: false,
      showVerifyOTP: false,
      disableLoginOTP: false,
      paymentRID: "",
      loginUrl: "",
      merchantId: "",
      showIdMatrixVerification: true,
      isCustomerLoggedIn: false,
      showPaymentRadioButton: true,
      till_failure_id: "",
      till_failure_id_now: "",
      till_failure_id_later: "",
      isDeviceVerified: false,
      showDirectPayNowFromApiConfig: true,
      hidePayNow: false,
      showLogin: true,
      index: 0,
      otpLoginCode: "",
      phoneNumber: "",
      customerEmail: "",
      loggedIn: false,
      footerBind: false,
      isEmailVerified: false,
      disableEmailVerification: false,
      isEmailOtpInvalid: false,
      payNowLegals: {},
      privacyLegals: [],
      termsAndCondition: [],
      payLaterLegals: [],
      directDebitLegals: {},
      activePlan: {},
      additionalOpen: false,
      payNowAdditional: false,
      hideIdErrorBlock: true,
      requestUID: '',
      disableButtonAfterSubmit: false,
      maxFileSize: process.env.VUE_APP_FILE_MAX_SIZE_MB,
      visaCard: visaCard,
      masterCard: masterCard,
      amexCard: amexCard,
      dinersClubCard: dinersClubCard,
      unionpayCard: unionpayCard,
      directDebitCard: directDebitCard,
      amex: amex,
      amex1: amex1,
      general: general,
      general1: general1,
      tabIndex: 'CARD',
      paymentMethods: ['CARD'],
      dateSuccess: false,
      dateError: true,
      showBsbValid: true,
      bsbSuccess: false,
      bsbError: true,
      cardType: "default",
      cardValid: true,
      cardValidity: true,
      cardFocused: false,
      securityFocused: false,
      cardSuccess: false,
      cvvSuccess: false,
      cardNameValid: false,
      accountNameValid: false,
      accountNumberValid: false,
      isInvalidCvv: false,
      paymentMethodDescription: "",
      paymentDetails: {},
      paymentRequestId: "",
    };
  },
  computed: {
    payLaterContent() {
      if (this.payLaterLegals[0]) {
      }
      return "";
    },
    payNowAmount() {
      return `$ ${this.addZeroes(this.formData.amount)} paid today`;
    },
    selectedPaymentType() {
      return this.formData.payment_type;
    },
    SurChargable() {
      let isSurChargable = true;
      for (const card of this.card_options) {
        if (parseFloat(card.surcharge) > 0) {
          isSurChargable = false;
        }
      }

      return isSurChargable;
    },
    handlePayLaterDisable() {
      let validPayment = true;
      if (this.tabIndex == 'CARD') {
        if (this.formData.name == "" || this.formData.card_expiry_date == "" || !this.card_status.validCvv || !this.card_status.validNumber || !this.isCardAllowed || this.errors.has('cardHolder')) {
          validPayment = false;
        }
        validPayment = this.disablePayLater || !this.toc_acceptance || !this.payNowAcceptance || !validPayment || this.showExpired || this.showExpDateIsRequired;
      } else if (this.tabIndex == "DIRECT_DEBIT") {
        if (this.formData.accountName == "" || this.formData.bsb == "" || this.formData.accountNumber == "" || this.errors.has('accountNumber')) {
          validPayment = false;
        }
        validPayment = !validPayment || this.disablePayLater || !this.toc_acceptance || !this.toc_acceptance_direct_debit || !this.showBsbValid;
      } else if (this.tabIndex == "EFT") {
        validPayment = !this.toc_acceptance;
      }
      return validPayment;
    },

    handlePayNowDisable() {
      let validPayment = true;
      if (this.formData.name == "" || this.formData.card_expiry_date == "" || !this.card_status.validCvv || !this.card_status.validNumber || !this.isCardAllowed || this.errors.has('cardHolder')) {
        validPayment = false;
      }

      return !validPayment || this.disablePayNow || !this.payNowAcceptance || this.showExpDateIsRequired || this.showExpired;
    }
  },
  watch: {
    "formData.sendConfirmText"(val) {
      if (this.emailPattern(val) === false) {
        this.sendConfirmTextError = "Invalid email address or mobile number";
        this.disableSendConfirmButton = true;
      } else {
        this.disableSendConfirmButton = false;
        this.sendConfirmTextError = "";
      }
    },
    "errors.items"(val) {
      if (this.errors.items.length > 0) {
        if (this.$refs.customCollapse) {
          this.$refs.customCollapse.maxHeight = "none !important";
        }
      } else {
        if (this.disablePayLater && !this.disableButtonAfterSubmit) {
          this.disablePayLater = false;
        }
      }
    },

    selectedPaymentType() {
      if (this.formData.payment_type !== "now") {
        if (!this.disableDeviceVerification) {
          this.showAcceptAgreement = false;
          this.showPayNow = false;
          this.showPaymentOptions = false;
        }

        this.formData.selectedPaymentPlan = this.quote_data.find(
          (item) => item.currentPlan._id === this.formData.payment_type
        );

        const currentPlan = this.formData.selectedPaymentPlan && this.formData.selectedPaymentPlan.currentPlan;

        if (currentPlan) {
          this.paymentMethodDescription = {
            paymentSectionHeading: currentPlan.paymentSectionHeading ? currentPlan.paymentSectionHeading : "Payment method",
            paymentMethodDescription: currentPlan.paymentMethodDescription ? currentPlan.paymentMethodDescription : "",
          }
        }

        if (!this.isCustomerLoggedIn) {
          this.showIdMatrixVerification = true;
        } else {
          this.showMobileVerification = false;
          this.showPaymentRadioButton = true;
        }

        this.showPayNow = false;

        if (this.idMatrix.verified) {
          this.showMobileVerification = true;
        }

        if (this.isEmailVerified) {
          this.formData.commsPreference.email = true;
          this.showPaymentOptions = true;
          this.showPaymentPlan = true;
          this.showAcceptAgreement = true;
        }
      } else {
        this.showPaymentOptions = true;
        this.showPayNow = true;
        this.showPaymentRadioButton = false;
        this.showPaymentPlan = false;
        this.showAcceptAgreement = false;
        this.showMobileVerification = false;
      }
    },

    "formData.bsb"(val) {
      if (val.length == 0) {
        this.showBsbValid = true;
      }
    }
  },
  methods: {
    ...mapActions("payment", [
      "getPaymentDetails",
      "makePayment",
      "sendConfirmation",
      "sendFailedConfirmation",
      "sendDeviceVerificationCodeForPaylater",
      "verifyDeviceForPaylater",
      "verifyIDMatrix",
      "submitOTP",
      "resendOTP",
      "sendEmailVerificationCodeForPaylater",
      "verifyEmailForPaylater",
      "getProductCards",
    ]),
    ...mapActions("paymentRequest", ["getPlanByCustomPlanId"]),
    ...mapActions("apiLog", ["storeApiLogRequest", "storeApiLogResponse"]),
    ...mapActions("legals", ["fetchAllLegals"]),

    isNumber($event) {
      let keyCode = ($event.keyCode ? $event.keyCode : $event.which);
      if ((keyCode < 48 || keyCode > 57) && keyCode !== 46) { // 46 is dot
        $event.preventDefault();
      }
    },

    async getCardsForPayLater() {
      await this.getProductCards().then((res) => {
        this.cardsForPaylater = res.data.data;
        this.cardsForPaylater.map((item) => {
          item.card = {};
          item.card.icon = item.icon;
          item.card.slug = item.slug;
        });
      }).catch(() => {});
    },

    updateTotal(total) {
      this.total = total;
    },

    emailPattern(val) {
      const regx = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$|^04\(?\d{8}\)?$/;
      return regx.test(val);
    },

    clearCard() {
      this.formData.name = "";
      this.formData.exp_date = "";
      this.formData.card_expiry_date = "";
      this.formData.exp_month = "";
      this.formData.exp_year = "";
      this.toc_acceptance = false;
      this.toc_acceptance_direct_debit = false;
      this.payNowAcceptance = false;
      this.showCardNumberIsRequired = false;
      this.isCardAllowed = true;
      this.cardValid = true;
      this.showCvcIsRequired = false;
      this.isInvalidCvv = false;
      this.showExpired = false;
      this.showExpDateIsRequired = false;
      this.dateSuccess = false;
      this.dateError = true;
      this.showBsbValid = true;
      this.bsbSuccess = false;
      this.bsbError = true;
      this.cardValidity = true;
      this.cardSuccess = false;
      this.cvvSuccess = false;
      this.cardNameValid = false;
      this.accountNameValid = false;
      this.accountNumberValid = false;
      this.$validator.reset();
      this.errors.clear();
      this.selectTab(this.paymentMethods[0]);
    },

    clearFieldsForPaymentType(type) {
      this.clearCard();

      if (type === "pay-now") {
        // clear mobile if id matrix verified but device is not and user switches to pay-now
        if (this.idMatrix.verified && !this.isDeviceVerified) {
          this.formData.maskedMobileNumber = "";
        }

        return;
      } else {
        this.formData.unit_number = "";
        this.formData.street_name = "";
        this.formData.suburb = "";
        this.formData.state = "";
        this.formData.postcode = "";
        this.formData.first_name = "";
        this.formData.middle_name = "";
        this.formData.last_name = "";
        this.formData.date_of_birth = "";
        this.formData.licenseDetails.license_number = "";
        this.formData.licenseDetails.state = "";
        this.formData.licenseDetails.expiry_date = "";
        this.formData.passportDetails.passport_number = "";
        this.formData.passportDetails.issue_date = "";
        this.formData.passportDetails.expiry_date = "";

        return;
      }
    },

    async savePaymentType(event) {
      if (event == "pay-now" || event == "now") {
        this.paymentTypeForSummary = "pay-now";

        if (this.productCustomFields.length > 0) {
          this.inputArrayFiltered = this.inputArray.filter((item) => {
            return item.fieldType == "custom";
          });
        }

        this.card_options = this.paymentResponseData.paymentCards;
        this.till_public_key = this.paymentResponseData.paymentDetails.accountId.publicIntegration;

        if (this.payNowConfirmationError == true) {
          this.showConfimationForError = true;
          this.showConfirmationForPayLaterError = false;

          if (this.till_failure_id_now) {
            this.till_failure_id = this.till_failure_id_now;
          } else {
            this.till_failure_id = "";
          }
        } else {
          this.till_failure_id = "";
          this.showConfimationForError = false;
          this.showConfirmationForPayLaterError = false;
        }

        if (this.$refs.customCollapse) {
          this.$refs.customCollapse.maxHeight = "none !important";
        }

        this.paymentMethods = ["CARD"];
      } else {
        this.getSelelectedFields(event);
        this.inputArrayFiltered = this.inputArray;

        this.paymentTypeForSummary = "pay-later";
        this.card_options = this.cardsForPaylater;

        if (this.paymentResponseData.tillConnectorDetails) {
          this.till_public_key = this.paymentResponseData.tillConnectorDetails.publicIntegration;
        }

        if (this.payLaterConfirmationError == true) {
          this.showConfimationForError = false;
          this.showConfirmationForPayLaterError = true;
          if (this.till_failure_id_later) {
            this.till_failure_id = this.till_failure_id_later;
          } else {
            this.till_failure_id = "";
          }
        } else {
          this.till_failure_id = "";
          this.showConfimationForError = false;
          this.showConfirmationForPayLaterError = false;
        }

        if (this.$refs.customCollapse) {
          this.$refs.customCollapse.maxHeight = "none !important";
        }

        const currentPlan = this.formData.selectedPaymentPlan.currentPlan;
        this.paymentMethods = currentPlan && currentPlan.paymentMethods && currentPlan.paymentMethods.paymentPage && currentPlan.paymentMethods.paymentPage.length > 0 ? currentPlan.paymentMethods.paymentPage : ["EFT"];
        this.paymentMethods.sort();
      }

      await this.clearFieldsForPaymentType(event);
    },

    moment(date) {
      return moment(date).format("DD-MMM-YYYY");
    },

    pfFormatDate(date) {
      return moment(date).format("DD/MM/YYYY");
    },
    async initializePaymentObject() {
      try {
        // eslint-disable-next-line no-undef
        if (this.paymentJSObject != undefined) {
          delete this.paymentJSObject;
          return;
        } else {
          this.paymentJSObject = new PaymentJs();
          await this.paymentJSObject.init(this.till_public_key, "number_div", "cvv_div", this.setPaymentStyle);
        }
      } catch(e) {
        console.log("Error initialization payment JS", e);
        sentryLog(e);
      }
    },

    setPaymentStyle(payment) {
      payment.setNumberStyle({
        "width": "100%",
        "border": "none",
        "box-sizing": "border-box",
        "height": "28px",
        "outline": 0,
        "font-family": "Arial",
        "font-size": "16px",
        "color": "#0D0F11",
        "padding": "0 8px",
      });
      payment.setCvvStyle({
        "width": "100%",
        "border": "none",
        "outline": 0,
        "font-family": "Arial",
        "box-sizing": "border-box",
        "height": "28px",
        "font-size": "16px",
        "color": "#0D0F11",
        "padding": "0 8px",
      });
      payment.numberOn("input", this.checkCardValidity);
      payment.cvvOn("input", this.checkCvvValidity);
      payment.setNumberPlaceholder("0000 0000 0000 0000");
      payment.numberOn('focus', this.handleCardFocus);
      payment.cvvOn('focus', this.handleCvvFocus);
      payment.numberOn('blur', this.handleCardBlur);
      payment.cvvOn('blur', this.handleCvvBlur);
    },

    checkCardValidity(data) {
      this.card_status = data;
      this.cardValid = true;
      if (this.card_status.numberLength == 0) {
        this.showCardNumberIsRequired = true;
        this.cardType = "";
      }

      if (this.card_status.numberLength > 0) {
        this.showCardNumberIsRequired = false;
      }

      let checkCardAllowedStatus = this.card_options.find((card_option) => {
        return card_option.card.slug === data.cardType;
      });

      if (data.cardType) {
        this.cardType = data.cardType;
        this.cardValidity = data.validNumber;
        this.cardSuccess = data.validNumber && checkCardAllowedStatus;
        if (!checkCardAllowedStatus) {
          this.isCardAllowed = false;
        } else {
          this.isCardAllowed = true;
        }
      } else {
        this.cardSuccess = false;
      }
    },

    checkCvvValidity(data) {
      this.card_status = data;
      this.cvvSuccess = data.validCvv;
      if (this.card_status.cvvLength == 0) {
        this.showCvcIsRequired = true;
      }
      if (this.card_status.cvvLength > 0) {
        this.showCvcIsRequired = false;
      }
      this.isInvalidCvv = !data.validCvv;
    },

    handleCardBlur() {
      this.cardValid = this.cardValidity;
      this.cardFocused = false;
    },

    handleCardFocus() {
      this.cardFocused = true;
    },

    handleCvvBlur() {
      this.securityFocused = false;
    },

    handleCvvFocus() {
      this.securityFocused = true;
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    formatNumber(num) {
      num = String(num);
      const dec = num.split(".")[1];
      return Number(num).toLocaleString(undefined, {minimumFractionDigits: 0});
    },

    navigateToNewTab(routeParams) {
      let routeData = this.$router.resolve(routeParams);
      window.open(routeData.href, "_blank");
    },

    prepareSchedule() {
      let rule = { dtstart: new Date(this.paymentRequestFrequency.startDate) };

      if (this.paymentRequestFrequency.every === "week") {
        rule.freq = RRule.WEEKLY;
        rule.interval = this.paymentRequestFrequency.interval ? parseInt(this.paymentRequestFrequency.interval) : 0;
      } else {
        rule.freq = RRule.MONTHLY;
        rule.interval = this.paymentRequestFrequency.interval ? parseInt(this.paymentRequestFrequency.interval) : 0;
      }

      if (this.paymentRequestFrequency.endType === "by") {
        rule.until = this.paymentRequestFrequency.endDate ? new Date(this.paymentRequestFrequency.endDate) : new Date();
      } else {
        rule.count = this.paymentRequestFrequency.endAfterNumberOfPayments ? parseInt(this.paymentRequestFrequency.endAfterNumberOfPayments) : 1;
      }

      this.installment(rule);
    },

    installment(payload) {
      const rule = new RRule(payload);
      this.paymentList(rule.all());
    },

    paymentList(schedule) {
      let list = [];

      if (schedule.length > 2) {
        list.push({
          label: "First payment",
          value: schedule[0],
        });
        this.firstPayment = schedule[0];
        list.push({
          label: "Second payment",
          value: schedule[1],
        });
        list.push({
          label: "Last payment",
          value: schedule[schedule.length - 1],
        });
      } else if (schedule.length === 2) {
        list.push({
          label: "First payment",
          value: schedule[0],
        });
        this.firstPayment = schedule[0];

        list.push({
          label: "Last payment",
          value: schedule[schedule.length - 1],
        });
      } else if (schedule.length === 1) {
        list.push({
          label: "Payment",
          value: schedule[0],
        });
        this.firstPayment = schedule[0];
      }

      this.totalNumberOfPayments = schedule.length;
      this.paymentSchedules = list;
      let endStatement = "";

      if (this.paymentRequestFrequency.endType === "by") {
        endStatement = "\n and ending by " + this.momentDate(this.paymentRequestFrequency.endDate);
      } else {
        endStatement = " and ending after " + this.paymentRequestFrequency.endAfterNumberOfPayments + " payments \nLast payment on " + this.momentDate(schedule[schedule.length - 1]);
      }

      this.scheduleDescription = "Every " + this.paymentRequestFrequency.interval + " " + this.paymentRequestFrequency.every + "s, " + "starting on " + this.momentDate(this.firstPayment) + "\n </br> </br>" + endStatement;

      this.scheduleDescriptionArray.push(
        this.paymentRequestFrequency.interval == 1
          ? `${this.paymentRequestFrequency.every.charAt(0).toUpperCase() + this.paymentRequestFrequency.every.slice(1)}ly, starting on ` + this.momentDate(this.firstPayment)
          : "Every " + this.paymentRequestFrequency.interval + " " + this.paymentRequestFrequency.every + "s, starting on " + this.momentDate(this.firstPayment)
      );

      if (this.paymentRequestFrequency.endType === "by") {
        this.scheduleDescriptionArray.push("Ending by " + this.momentDate(this.paymentRequestFrequency.endDate));
      } else {
        this.scheduleDescriptionArray.push("Ending after " + this.paymentRequestFrequency.endAfterNumberOfPayments + " payments");
        this.scheduleDescriptionArray.push("Last payment on " + this.momentDate(schedule[schedule.length - 1]));
      }
    },

    momentDate(date) {
      return moment(date).format("DD MMM YYYY");
    },

    checkExpiryDate(event) {
      const expiryDate = this.formData.card_expiry_date.split("/");
      const firstValue = expiryDate[0].charAt(0);

      if (firstValue >= 2) {
        this.formData.card_expiry_date = "0" + firstValue;
      }
      if (event.key == '/' && this.formData.card_expiry_date.length == 1) {
        this.formData.card_expiry_date = "0" + firstValue + "/";
      }

      if (expiryDate.length == 2) {
        const fullDate = `20${expiryDate[1]}-${expiryDate[0]}`;
        this.formData.exp_month = expiryDate[0];
        this.formData.exp_year = expiryDate[1];

        if (moment(fullDate).isSame(moment(new Date()).format("YYYY-MM"))) {
          return false;
        }
        return !moment(fullDate).isAfter(new Date());
      }

      return false;
    },

    handleBsb() {
      const bsbData = this.formData.bsb.split("-");

      if (bsbData.length == 2 && bsbData[1].length == 3) {
        this.showBsbValid = true;
      } else {
        this.showBsbValid = false;
      }

      this.bsbSuccess = this.showBsbValid;
      this.bsbError = this.showBsbValid;
    },

    checkFieldValidation(field) {
      if (field == "cardName") {
        this.cardNameValid = this.formData.name.length > 1 && !this.errors.has("cardHolder");
      } else if (field == "accountName") {
        this.accountNameValid = this.formData.accountName.length > 1;
      } else if (field == "accountNumber") {
        this.accountNumberValid = this.formData.accountNumber.length > 1 && !this.errors.has("accountNumber");
      }
    },

    async submitNormalOrRecurringPayment() {
      if (this.handlePayNowDisable) {
        return;
      }
      this.disablePayNow = true;
      this.showExpired = false;
      this.showCardNumberIsRequired = false;
      this.showCvcIsRequired = false;
      this.showNameIsRequired = false;
      this.showExpDateIsRequired = false;
      this.showExpDateIsRequired = false;

      if ((Object.keys(this.card_status).length === 0 && Object.getPrototypeOf(this.card_status) === Object.prototype) || this.card_status.numberLength == 0) {
        this.showCardNumberIsRequired = true;
        this.disablePayNow = false;
      }

      if ((Object.keys(this.card_status).length === 0 && Object.getPrototypeOf(this.card_status) === Object.prototype) || this.card_status.cvvLength == 0) {
        this.showCvcIsRequired = true;
        this.disablePayNow = false;
      }

      const valid = await this.$validator.validate();

      if (!valid) {
        this.disablePayNow = false;
        this.showErrorMessage("Missing information", "Please check all fields and try again");
        return;
      }

      if (this.formData.name === "") {
        this.disablePayNow = false;
        return;
      }

      if (this.formData.card_expiry_date === "") {
        this.showExpDateIsRequired = true;
        this.disablePayNow = false;
        return;
      }
      if (this.showExpired) {
        this.disablePayNow = false;
        return;
      }

      if (this.card_status.validCvv === true && this.card_status.validNumber === true) {
        let exp_date = moment().set("year", this.formData.exp_year).set("month", this.formData.exp_month);
        exp_date = moment(exp_date, "MM-YYYY");
        this.formData.exp_date = exp_date._d;

        if (this.isCardAllowed) {
          this.interceptSubmit();
        } else {
          this.disablePayNow = false;
          this.showWarningMessage("Card", "Card type not allowed, please use one of the card types indicated below");
        }
      } else {
        this.disablePayNow = false;
        let message = this.card_status.validCvv === false
              ? "Please enter valid cvc."
              : this.card_status.validNumber === false
              ? "Please enter valid card number."
              : "Please enter valid card.";
        this.showWarningMessage('Card', message);
      }
    },

    async interceptSubmit() {
      let cardData = {
        card_holder: this.formData.name,
        month: this.formData.exp_month,
        year: this.formData.exp_year,
      };
      // FP-841: store card details sent to till
      await this.storeRequestDataSendedToTill(cardData);

      this.paymentJSObject.tokenize(cardData, this.submitPaymentForm,
        function (errors) {
          //error callback function
          //render error information here
          this.showErrorMessage('Card', errors[0].message);

          // store response data from till
          if (this.requestUID) {
            const payload = {
              uid: this.requestUID,
              data: errors
            };
            this.storeApiLogResponse(payload);
          }
        });
    },

    submitPaymentForm(token, cardData) {
      // FP-841: store reponse data from till
      if (this.requestUID) {
        const payload = {
          uid: this.requestUID,
          data: {
            token,
            cardData
          }
        };
        this.storeApiLogResponse(payload);
      }
      this.disablePayNow = true;
      this.formData.transaction_token = token;
      this.formData.transaction_data = cardData;
      this.formData.paymentRequestId = this.paymentRequestId;
      this.formData.periodLength = this.totalNumberOfPayments;
      this.formData.scheduleText = this.scheduleDescriptionArray;
      this.formData.payNowUsedCard = this.card_options.find((card_option) => {
        return card_option.card.slug === this.card_status.cardType;
      });

      this.formData.payment_mode = "now";
      this.$vs.loading();
      let phoneNumber = this.formData.maskedMobileNumber;
      this.formData.mobile = phoneNumber.split(" ").join("");

      //need to verify this whether to send or not custom fields
      this.formData.customFields = this.defaultCustomFields.filter((item) => {
        return item.fieldType === "custom";
      });

      const fileInCustomFields = this.formData.customFields.filter((field) => {
        return field.type === "file" && field.hasOwnProperty("selectedFile");
      });

      const paymentFormData = new FormData();

      if (fileInCustomFields.length > 0) {
        for (let index = 0; index < fileInCustomFields.length; index++) {
          paymentFormData.append(
            "customFiles",
            fileInCustomFields[index].selectedFile,
            `${fileInCustomFields[index].label
              .split(" ")
              .join("-")
              .split("/")
              .join("-")}@@${fileInCustomFields[index].selectedFile.name}`
          );
        }
      }

      if (this.formData.customFields.length > 0) {
        this.formData.customFields
          .filter((el) => el.type === "date")
          .map((el) => {
            el.value = el.value ? moment(el.value).format("YYYY-MM-DD") : ""
            return el;
          });
      }

      paymentFormData.append("formData", JSON.stringify(this.formData));
      let obj = {
        obj: paymentFormData,
        config: {
          header: {
            "Content-Type": "application/json",
          },
        },
      };

      this.makePayment(obj).then(
        (res) => {
          this.$vs.loading.close();

          if (res.data.message == '') {
            return;
          }

          this.disablePayNow = false;
          this.showSuccessBox = true;
          this.hideCardWhenSuccess = false;
          (this.showCardInputBox = false), this.$vs.loading.close();

          if (res.data.data.success === false) {
            this.showErrorMessage('Payment', res.data.data.errors[0].errorMessage);
            this.disablePayNow = false;
            this.payNowConfirmationError = true;
            this.showConfimationForError = true;
            this.failureReason = res.data.data.errors[0].errorMessage;

            if (res.data.data.uuid) {
              this.till_failure_id_now = `TX-${res.data.data.uuid}`;
              this.till_failure_id = `TX-${res.data.data.uuid}`;
            }
          } else {
            this.showConfirmationForSuccess = true;
            this.showConfimationForError = false;
            this.till_response_uuid = `TX-${res.data.data.uuid}`;
          }
        },
        (error) => {
          this.$vs.loading.close();
          this.disablePayNow = false;
          this.payNowConfirmationError = true;
          this.showConfimationForError = true;
          this.failureReason = error.data.message;
          this.showErrorMessage('Payment', error.data.message);
        }
      );
    },

    // FP-841: store card details sent to till
    async storeRequestDataSendedToTill (cardData) {
      const payload = {
        cardDetail: { ...cardData, ...this.card_status },
        provider: "Till",
        url: "https://secure.tillpayments.com/v1/{{TOKEN}}/tokenize/creditcard",
        paymentRequestId: this.paymentRID,
      }

      const apiLogRequest = await this.storeApiLogRequest(payload);

      if (apiLogRequest) {
        this.requestUID = apiLogRequest.data.data;
      }
    },

    async submitPayLaterPayment() {
      if (this.handlePayLaterDisable) {
        return;
      }
      this.disablePayLater = true;
      this.disableButtonAfterSubmit = true;
      this.showExpired = false;
      this.showCardNumberIsRequired = false;
      this.showCvcIsRequired = false;
      this.showNameIsRequired = false;
      this.showExpDateIsRequired = false;
      this.showExpDateIsRequired = false;

      if (this.tabIndex == "CARD") {
        if ((Object.keys(this.card_status).length === 0 && Object.getPrototypeOf(this.card_status) === Object.prototype) || this.card_status.numberLength == 0) {
          this.showCardNumberIsRequired = true;
          this.disablePayLater = false;
        }

        if ((Object.keys(this.card_status).length === 0 && Object.getPrototypeOf(this.card_status) === Object.prototype) || this.card_status.cvvLength == 0) {
          this.showCvcIsRequired = true;
          this.disablePayLater = false;
        }
      }
      const valid = await this.$validator.validate();

      if (!valid) {
        this.disablePayLater = false;
        this.showWarningMessage("Missing information", "Please check all fields and try again");
        return;
      }

      if (this.formData.payment_method_type === "uuid") {
        this.sendDataFromUUID();
        return;
      }

      if (this.tabIndex == "CARD") {
        if (this.formData.name === "") {
          this.showNameIsRequired = true;
          this.disablePayLater = false;
        }

        if (this.formData.card_expiry_date === "") {
          this.showExpDateIsRequired = true;
          this.disablePayLater = false;
          return;
        }

        if (this.showExpired) {
          this.disablePayLater = false;
          return;
        }
      }

      this.formData.payment_mode = "later";
      if (this.tabIndex == "CARD" && this.card_status.validCvv === true && this.card_status.validNumber === true) {
        let exp_date = moment().set("year", this.formData.exp_year).set("month", this.formData.exp_month);
        exp_date = moment(exp_date, "MM-YYYY");
        this.formData.exp_date = exp_date._d;

        if (this.isCardAllowed) {
          this.submitDataForPayLater();
        } else {
          this.disablePayLater = false;
          this.showWarningMessage("Card", "Card type not allowed, please use one of the card types indicated below");
        }
      } else if (this.tabIndex == "DIRECT_DEBIT" && this.showBsbValid === true && this.accountNumberValid === true) {
        this.submitDebitDataForPayLater();
      } else if (this.paymentMethods.length == 1 && this.paymentMethods.includes("EFT")) {
        this.formData.customerSelectedPaymentMethod = "EFT";
        this.submitEFTForPayLater();
      } else {
        this.disablePayLater = false;
        let message = "Please enter valid details.";

        if (this.tabIndex == "CARD") {
          if (this.card_status.validCvv === false) {
            message = "Please enter valid cvc.";
          } else if (this.card_status.validNumber === false) {
            message = "Please enter valid card number.";
          }
        }

        this.showErrorMessage('Card', message);
      }
    },

    async submitDataForPayLater() {
      let self = this
      let newCardData = {
        card_holder: this.formData.name,
        month: this.formData.exp_month,
        year: this.formData.exp_year,
      };
      try {
        // FP-841: store card details sent to till
        await this.storeRequestDataSendedToTill(newCardData);

        await this.paymentJSObject.tokenize(
          newCardData,
          this.sendPayLaterData,
          function (errors) {
            self.showErrorMessage('Payment', errors[0].message);

            // store response data from till
            if (this.requestUID) {
              const payload = {
                uid: this.requestUID,
                data: errors
              };
              this.storeApiLogResponse(payload);
            }
          }
        );
      } catch(ex) {
        this.showWarningMessage('Payment', "Please reload the page. And try again.");
      }
    },

    async submitDebitDataForPayLater() {
      let self = this
      let paymentData = {
        account_holder: this.formData.accountName,
        bsb: this.formData.bsb,
        account_number: this.formData.accountNumber,
      };

      try {
        this.sendPayLaterData("", paymentData);
      } catch(ex) {
        this.showWarningMessage('Payment', "Please reload the page. And try again.");
      }
    },

    async submitEFTForPayLater() {
      try {
        this.sendPayLaterData("", {});
      } catch(ex) {
        this.showWarningMessage('Payment', "We are unable to process your request at the moment. Please try again in a few minutes.");
      }
    },

    formatDate(date) {
      let d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();

      if (month.length < 2) {
        month = "0" + month;
      }

      if (day.length < 2) {
        day = "0" + day;
      }

      return [year, month, day].join("-");
    },

    handleOnComplete(value) {
      this.formData.verification_code = value;
      this.isOtpComplete = true;
    },

    handleOnChange(value) {},

    handleClearInput() {
      this.$refs.otpInput.clearInput();
    },

    sendPayLaterData(token, cardData) {
      // store response data from till
      if (this.requestUID) {
        const payload = {
          uid: this.requestUID,
          data: {
            token,
            cardData
          }
        };
        this.storeApiLogResponse(payload);
      }

      if (token) {
        this.formData.transaction_token = token;
      }
      this.formData.transaction_data = cardData;
      this.formData.paymentRequestId = this.paymentRequestId;
      this.formData.accountTypeId = this.formData.pay_later_type;
      this.formData.date_of_birth = this.formatDate(this.formData.date_of_birth);
      this.formData.licenseDetails.expiry_date = this.formatDate(this.formData.licenseDetails.expiry_date);
      this.formData.passportDetails.issue_date = this.formatDate(this.formData.passportDetails.issue_date);
      this.formData.passportDetails.expiry_date = this.formatDate(this.formData.passportDetails.expiry_date);
      this.formData.customFields = this.defaultCustomFields.filter((item) => {return item.fieldType === "custom";});

      const paymentFormData = new FormData();
      const fileInCustomFields = this.formData.customFields.filter((field) => {return field.type === "file" && field.hasOwnProperty("selectedFile");});

      if (fileInCustomFields.length > 0) {
        for (let index = 0; index < fileInCustomFields.length; index++) {
          paymentFormData.append(
            "customFiles",
            fileInCustomFields[index].selectedFile,
            `${fileInCustomFields[index].label
              .split(" ")
              .join("-")
              .split("/")
              .join("-")}@@${fileInCustomFields[index].selectedFile.name}`
          );
        }
      }

      this.formData.productCustomFields = this.defaultCustomFields.filter((item) => {return item.fieldType === "product";});

      const fileInProductFields = this.formData.productCustomFields.filter(
        (field) => {
          return field.type === "file" && field.hasOwnProperty("selectedFile");
        }
      );

      if (fileInProductFields.length > 0) {
        for (let index = 0; index < fileInProductFields.length; index++) {
          paymentFormData.append(
            "productFiles",
            fileInProductFields[index].selectedFile,
            `${fileInProductFields[index].label
              .split(" ")
              .join("-")
              .split("/")
              .join("-")}@@${fileInProductFields[index].selectedFile.name}`
          );
        }
      }

      this.formData.productCustomFieldsNotViewable = this.productCustomFieldsNotViewable

      if (this.formData.productCustomFields.length > 0) {
        // stringify all address fileds and add them to value
        this.formData.productCustomFields
          .filter((el) => el.type === "address")
          .map((el) => {
            delete this.productAddressFieldArray[el._id].rawAddress;
            el.value = JSON.stringify(this.productAddressFieldArray[el._id]);
            return el;
          });
        this.formData.productCustomFields
          .filter((el) => el.type === "date")
          .map((el) => {
            el.value = el.value ? moment(el.value).format("YYYY-MM-DD") : ""
            return el;
          });
      }

      if (this.formData.customFields.length > 0) {
        this.formData.customFields
          .filter((el) => el.type === "date")
          .map((el) => {
            el.value = el.value ? moment(el.value).format("YYYY-MM-DD") : ""
            return el;
          });
      }

      let phoneNumber = this.formData.maskedMobileNumber;
      this.formData.mobile = phoneNumber.split(" ").join("");
      paymentFormData.append("formData", JSON.stringify(this.formData));

      let obj = {
        obj: paymentFormData,
        config: {
          header: {
            "Content-Type": "application/json",
          },
        },
      };

      this.$vs.loading();

      this.makePayment(obj).then(
        (res) => {
          this.$vs.loading.close();

          if (res.data.message == '') {
            return;
          }

          if (["Active", "Submitted"].includes(res.data.data.paymentStatus )) {
            this.showConfirmationForPaylaterSuccess = true;
            this.showConfirmationForPayLaterError = false;
            this.disablePayLater = true;
            this.hideCardWhenSuccess = false;
            this.showSuccessBox = true;
            this.sendConfirmationForTransaction();
          } else {
            this.showErrorMessage('Payment', res.data.data.Message);
            this.payLaterConfirmationError = true;
            this.showConfirmationForPayLaterError = true;
            this.failureReason = res.data.data.Message;
            if (res.data.data.uuid) {
              this.till_failure_id_later = `TX-${res.data.data.uuid}`;
              this.till_failure_id = `TX-${res.data.data.uuid}`;
            }
          }
        },
        (error) => {
          if (error.status == 422)  {
            error.data.data.forEach(item => {
              if (!this.errors.items.find(error => error.field == item.field)) {
                this.errors.add(item);
              }
            });
          }

          this.disablePayLater = false;
          this.$vs.loading.close();
          this.showErrorMessage('Payment', error.data.message);
          this.payLaterConfirmationError = true;
          this.showConfirmationForPayLaterError = true;
          this.failureReason = error.data.message;

          if (error.data.data && error.data.data.uuid) {
            this.till_failure_id_later = `TX-${error.data.data.uuid}`;
            this.till_failure_id = `TX-${error.data.data.uuid}`;
          }

          this.paylaterFailurePayload = error.data;
        }
      );
    },

    sendDataFromUUID() {
      this.formData.payment_mode = "later";
      this.formData.transaction_data = {card_type: "visa"};
      this.formData.paymentRequestId = this.paymentRequestId;
      this.formData.accountTypeId = this.formData.pay_later_type;
      this.formData.date_of_birth = this.formatDate(this.formData.date_of_birth);
      this.formData.licenseDetails.expiry_date = this.formatDate(this.formData.licenseDetails.expiry_date);
      this.formData.passportDetails.issue_date = this.formatDate(this.formData.passportDetails.issue_date);
      this.formData.passportDetails.expiry_date = this.formatDate(this.formData.passportDetails.expiry_date);

      let phoneNumber = this.formData.maskedMobileNumber;
      this.formData.mobile = phoneNumber.split(" ").join("");

      const paymentFormData = new FormData();
      paymentFormData.append("formData", JSON.stringify(this.formData));

      let obj = {
        obj: paymentFormData,
        config: {
          header: {
            "Content-Type": "application/json",
          },
        },
      };

      this.$vs.loading();
      this.makePayment(obj).then(
        (res) => {
          this.$vs.loading.close();

          if (res.data.message == '') {
            return;
          }

          if (["Active", "Submitted"].includes(res.data.data.paymentStatus )) {
            this.showConfirmationForPaylaterSuccess = true;
            this.showConfirmationForPayLaterError = false;
            this.disablePayLater = true;
            this.sendConfirmationForTransaction();
          } else {
            this.showErrorMessage('Payment', res.data.data.Message);
            this.payLaterConfirmationError = true;
            this.showConfirmationForPayLaterError = true;
            this.failureReason = res.data.data.Message;
            if (res.data.data.uuid) {
              this.till_failure_id_later = `TX-${res.data.data.uuid}`;
              this.till_failure_id = `TX-${res.data.data.uuid}`;
            }
          }
        },
        (error) => {
          this.disablePayLater = false;
          this.$vs.loading.close();
          this.showErrorMessage('Payment', error.data.message);
          this.payLaterConfirmationError = true;
          this.showConfirmationForPayLaterError = true;
          this.failureReason = error.data.message;
          this.paylaterFailurePayload = error.data;

          if (error.data.data && error.data.data.uuid) {
            this.till_failure_id_later = `TX-${error.data.data.uuid}`;
            this.till_failure_id = `TX-${error.data.data.uuid}`;
          }
        }
      );
    },

    sendConfirmationForTransaction() {
      let confirmationData = {};
      confirmationData.paymentRequestId = this.paymentRequestId;
      confirmationData.email = this.formData.email;

      let phoneNumber = this.formData.maskedMobileNumber;
      this.formData.mobile = phoneNumber.split(" ").join("");

      confirmationData.mobile = this.formData.mobile;
      confirmationData.sendConfirmText = this.formData.sendConfirmText;
      confirmationData.paymentTypeForSummary = this.paymentTypeForSummary;
      this.$vs.loading();
      this.sendConfirmation(confirmationData).then(
        (_res) => {
          this.$vs.loading.close();
          this.showSuccessMessage('Confirmation', 'Confirmation has been sent successfully.');
        },
        (error) => {
          this.$vs.loading.close();
          this.showErrorMessage('Payment', error.data.message);
        }
      );
    },

    sendConfirmationForFailedTransaction() {
      let phoneNumber = this.formData.maskedMobileNumber;
      this.formData.mobile = phoneNumber.split(" ").join("");
      let confirmationData = {};

      confirmationData.paymentRequestId = this.paymentRequestId;
      confirmationData.email = this.formData.email;
      confirmationData.transactionID = this.till_failure_id;
      confirmationData.mobile = this.formData.mobile;
      confirmationData.sendConfirmText = this.formData.sendConfirmText;

      this.$vs.loading();
      this.sendFailedConfirmation(confirmationData).then(
        (_res) => {
          this.$vs.loading.close();
          this.showSuccessMessage('Confirmation', 'Confirmation has been sent successfully.');
        },
        (error) => {
          this.$vs.loading.close();
          this.showErrorMessage('Payment', error.data.message);
        }
      );
    },

    verifyId() {
      let idMatrixVerificationObject = {
        id: this.paymentRequestId,
        enquiryId: this.idMatrix.enquiryId,
        familyName: this.formData.last_name,
        firstGivenName: this.formData.first_name,
        otherGivenName: this.formData.middle_name,
        dateOfBirth: moment(this.formData.date_of_birth).format("YYYY-MM-DD"),
        gender: this.formData.gender,
        property: this.formData.property,
        unitNumber: this.formData.unit_number,
        streetNumber: this.formData.street_number,
        streetName: this.formData.street_name,
        streetType: this.formData.street_type,
        suburb: this.formData.suburb,
        state: this.formData.state,
        postCode: this.formData.postcode,
        country: "AUS",
        consent: this.authorized_to_use,
        merchantId: this.merchantId,
        streetNameOnly: this.streetNameOnly,
        rawAddress : this.rawAddress,
      };

      if (this.formData.id_type === "DL") {
        idMatrixVerificationObject["license"] = {
          stateCode: this.formData.licenseDetails.state,
          number: this.formData.licenseDetails.license_number,
          cardNumber: this.formData.licenseDetails.card_number,
          expiryDate: this.formData.licenseDetails.expiry_date,
        };
      } else {
        idMatrixVerificationObject["passport"] = {
          countryCode: "AUS",
          number: this.formData.passportDetails.passport_number,
          issueDate: this.formData.passportDetails.issue_date,
          expiryDate: this.formData.passportDetails.expiry_date,
        };
      }
      this.$vs.loading();
      this.verifyIDMatrix(idMatrixVerificationObject).then((res) => {
        this.$vs.loading.close();
        if (res.data.data.overallOutcome === "ACCEPT") {
          this.idMatrix.verified = true;
          this.showMobileVerification = true;
          if (this.showConfimationForError == true) {
            this.showConfimationForError = false;
          }
        } else if (res.data.data.overallOutcome === "REJECT") {
          this.idMatrix.enquiryId = res.data.data.enquiryId;
          this.idMatrix.verified = false;
          this.hideIdErrorBlock = false;
          this.idMatrix.verifyCount += 1;
          this.showErrorMessage("ID Verification Failed",  this.idMatrix.verifyCount >= 3 ? "Unable to verify" : "Please try again");

          if (res.data.data.payLaterRemoved) {
            if (this.paymentRequestDetail.requestOptions.includes("pay-now")) {
              this.formData.payment_type = "now";
              this.savePaymentType("pay-now");
            } else if (res.data.data.payNowAdded) {
              this.paymentRequestDetail.requestOptions.push("pay-now");
              this.formData.payment_type = "now";
              this.savePaymentType("pay-now");
            }
          }
        } else {
          throw res;
        }
      }).catch((ex) => {
        this.$vs.loading.close();
        let errorMessage = ex.data.message;
        if (ex.status === 500) {
          errorMessage = "We are unable to process your request at the moment. Please try again in a few minutes."
        }
        this.showErrorMessage("ID Verification", errorMessage);
        this.hideIdErrorBlock = false;
      });
    },

    sendVerificationCode() {
      this.$validator.validate("mobile").then((result) => {
        if (result) {
          this.$vs.loading();

          let phoneNumber = this.formData.maskedMobileNumber;
          this.formData.mobile = phoneNumber.split(" ").join("");

          let deviceVerificationData = {
            paymentRequestId: this.paymentRequestId,
            mobile: this.formData.mobile,
          };

          this.sendDeviceVerificationCodeForPaylater(deviceVerificationData)
            .then((_response) => {
              this.$vs.loading.close();
              this.showSuccessMessage('Verification', 'Verification code has been sent successfully.');
              this.isOtpDisabled = false;
              this.showVerifyMobile = true;
            })
            .catch((ex) => {
              this.$vs.loading.close();
              this.showErrorMessage('Verification', ex.data.message);
            });
        }
      });
    },

    verifyMobile() {
      if (this.formData.verification_code === "") {
        this.showWarningMessage('Verification', "Please enter verification code");
      }

      this.$vs.loading();
      let phoneNumber = this.formData.maskedMobileNumber;
      this.formData.mobile = phoneNumber.split(" ").join("");

      let deviceVerificationData = {
        paymentRequestId: this.paymentRequestId,
        mobile: this.formData.mobile,
        verification_code: this.formData.verification_code,
      };

      this.verifyDeviceForPaylater(deviceVerificationData)
        .then((_response) => {
          this.$vs.loading.close();
          this.showSuccessMessage('Verification', 'Device has been verified successfully.');

          this.disableDeviceVerification = true;
          this.isDeviceVerified = true;
          this.formData.commsPreference.sms = true;
          this.index = 4;
        })
        .catch((ex) => {
          this.isOtpInvalid = true;
          document.getElementsByClassName("otp-input").forEach((element) => {
            element.style.borderColor = "#EB5757";
          });
          this.$vs.loading.close();
          this.showErrorMessage('Verification', ex.data.message);
        });
    },

    sendEmailVerificationCode() {
      this.$vs.loading();

      let emailVerificationData = {
        paymentRequestId: this.paymentRequestId,
        email: this.formData.email,
      };

      this.sendEmailVerificationCodeForPaylater(emailVerificationData)
        .then((_response) => {
          this.$vs.loading.close();
          this.showSuccessMessage('Verification', 'Verification code has been sent successfully.');
          this.isEmailOtpDisabled = false;
        })
        .catch((ex) => {
          this.$vs.loading.close();
          this.showErrorMessage('Verification', ex.data.message)
        });
    },

    verifyEmail() {
      if (this.formData.email_verification_code === "") {
        this.showWarningMessage('Email Verification', "Please enter verification code");
      }

      this.$vs.loading();

      let emailVerificationData = {
        paymentRequestId: this.paymentRequestId,
        email: this.formData.email,
        verification_code: this.formData.email_verification_code,
      };

      this.verifyEmailForPaylater(emailVerificationData)
        .then((_response) => {
          this.$vs.loading.close();
          this.showSuccessMessage('Verification', 'Email has been verified successfully.');
          this.showPaymentPlan = true;
          this.showAcceptAgreement = true;
          this.showPaymentOptions = true;
          this.isEmailVerified = true;
          this.formData.commsPreference.email = true;
          this.index = 5;
        })
        .catch((ex) => {
          this.isEmailOtpInvalid = true;
          document.getElementsByClassName("otp-input").forEach((element) => {
            element.style.borderColor = "#EB5757";
          });
          this.$vs.loading.close();
          this.showErrorMessage('Verification', ex.data.message);
        });
    },

    getAddressData: function (addressData, placeResultData, _id) {
      this.addressData = addressData;
      this.rawAddress = placeResultData.formatted_address;
      if (this.addressData.street_number) {
        this.formData.street_number = this.addressData.street_number;
      }

      if (this.addressData.postal_code) {
        this.formData.postcode = this.addressData.postal_code;
      }

      if (placeResultData.vicinity) {
        this.formData.suburb = placeResultData.vicinity;
      }

      if (this.addressData.administrative_area_level_1) {
        this.formData.state = this.addressData.administrative_area_level_1;
      }

      if (addressData.route) {
        let street_address = "";
        for (const address of placeResultData.address_components) {
          if (address.types.includes("street_number")) {
            street_address += address.short_name + " ";
          }

          if (address.types.includes("route")) {
            street_address += address.long_name;
            this.streetNameOnly = address.long_name;
          }
        }

        this.formData.street_name = street_address;
      }
    },

    cardNameChange(event) {
      const value = event.target.value;
      this.showNameIsRequired = value.length === 0;
    },

    handleExpiryCardField(event) {
      const value = event.target.value;
      this.showExpDateIsRequired = (value.length === 0);
      this.checkExpiryDate(event);
      if (value.length < 5) {
        this.dateSuccess = false;
        return;
      }
      this.showExpired = this.checkExpiryDate(event);
      this.dateSuccess = (value.length == 5 && !this.showExpired);
    },

    handleExpiryCardFieldOnBlur(event) {
      if (event.target.value.length < 5) {
        this.showExpired = true;
        this.dateSuccess = false;
      }
    },

    getAddressForProductField: function (addressData, placeResultData, _id) {
      this.addressData = addressData;

      if (this.addressData.street_number) {
        this.productAddressFieldArray[_id].street_number = this.addressData.street_number;
      }

      if (this.addressData.postal_code) {
        this.productAddressFieldArray[_id].postcode = this.addressData.postal_code;
      }

      if (placeResultData.vicinity) {
        this.productAddressFieldArray[_id].suburb = placeResultData.vicinity;
      }

      if (this.addressData.administrative_area_level_1) {
        this.productAddressFieldArray[_id].state = this.addressData.administrative_area_level_1;
      }

      if (addressData.route) {
        let street_address = "";
        for (const address of placeResultData.address_components) {
          if (address.types.includes("street_number")) {
            street_address += address.short_name + " ";
          }

          if (address.types.includes("route")) {
            street_address += address.long_name;
          }
        }
        this.productAddressFieldArray[_id].street_name = street_address;
      }

      const addressTimeOut = setTimeout(() => {
        this.$validator.validateAll();
        clearTimeout(addressTimeOut);
      }, 0);
    },

    resend() {
      this.$vs.loading();
      this.resendOTP({ merchantId: this.merchantId, id: this.id }).then((res) => {
        this.$vs.loading.close();
        this.showSuccessMessage('OTP', 'OTP sent successfully')
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showErrorMessage('OTP', ex.response.data.message)
      });
    },

    async submitOtp() {
      this.$vs.loading();
      return await this.submitOTP({
        merchantId: this.merchantId,
        id: this.formData.customerId,
        otpCode: this.otpLoginCode,
      }).then((res) => {
        this.$vs.loading.close();
        this.isDeviceVerified = true;
        this.idMatrix.verified = true;
        this.showPaymentOptions = true;
        this.isEmailVerified = true;
        this.formData.commsPreference.sms = true;
        this.formData.commsPreference.email = true;
        this.customerEmail = res.data.data.email;
        this.loggedIn = true;
        this.index = 3;

        if (this.formData.payment_type !== "now") {
          if (this.paymentResponseData.tillConnectorDetails) {
            this.till_public_key = this.paymentResponseData.tillConnectorDetails.publicIntegration;
          }
        }
      }).catch((ex) => {
        this.$vs.loading.close();
        return ex.response.data;
      });
    },

    loginIndex() {
      this.index += 1;
    },

    fetchPayNowLegals() {
      this.$vs.loading();
      this.fetchAllLegals("CARD").then((result) => {
        this.$vs.loading.close();
        let terms = result.data.data.legals.filter((legal) => legal.user === "CUSTOMER");
        terms = _.sortBy(terms, ["createdAt"]).reverse();
        this.payNowLegals = terms.length ? terms[0] : {};
        if (this.payNowLegals && this.payNowLegals.explicitTerms) {
          this.payNowLegals.explicitTerms = this.replaceLegalContent(
              this.payNowLegals.explicitTerms,
              {
                fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.payNowLegals._id}?type=${this.payNowLegals.nature}&fullDocument=true" target="_blank">${this.payNowLegals.documentName}</a>`,
                privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${this.privacyLegals._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.privacyLegals.documentName}</a>`,
                generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.termsAndCondition._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.termsAndCondition.documentName}</a>`,
              }
          );
        }
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showErrorMessage('Error', ex.message);
      });
    },

    fetchDirectDebitLegals() {
      this.$vs.loading();
      this.fetchAllLegals("DIRECT_DEBIT").then((result) => {
        this.$vs.loading.close();
        let terms = result.data.data.legals.filter((legal) => legal.user === "CUSTOMER");
        terms = _.sortBy(terms, ["version"]).reverse();
        this.directDebitLegals = terms.length ? terms[0] : {};

        if (this.directDebitLegals && this.directDebitLegals.explicitTerms) {
          this.directDebitLegals.explicitTerms = this.replaceLegalContent(
              this.directDebitLegals.explicitTerms,
              {
                fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.directDebitLegals._id}?type=${this.directDebitLegals.nature}&fullDocument=true" target="_blank">${this.directDebitLegals.documentName}</a>`,
                privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${this.privacyLegals._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.privacyLegals.documentName}</a>`,
                generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.termsAndCondition._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.termsAndCondition.documentName}</a>`,
              }
          );
        }
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showErrorMessage('Error', ex.message);
      });
    },

    fetchPrivacyLegals() {
      this.$vs.loading();
      this.fetchAllLegals("DASHBOARD").then((result) => {
        this.$vs.loading.close();
        this.privacyLegals = result.data.data.privacy;
        this.termsAndCondition = result.data.data.websiteTerms;
        this.fetchPayNowLegals();
        this.fetchDirectDebitLegals();
      }).catch((ex) => {
        this.$vs.loading.close();
        this.showErrorMessage('Error', ex.message);
      });
    },

    async fetchPlanByCustomFieldId() {
      await this.getPlanByCustomPlanId(this.quote_data[0].currentPlan.planId).then((res) => {
        this.activePlan = res.data.data[0];
        let terms = this.activePlan.legals.filter((legal) => legal.user === "CUSTOMER");
        terms = _.sortBy(terms, ["createdAt"]).reverse();
        this.payLaterLegals = terms[0];

        if (this.payLaterLegals.explicitTerms) {
          this.payLaterLegals.explicitTerms = this.replaceLegalContent(
            this.payLaterLegals.explicitTerms,
            {
              fullTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.payLaterLegals._id}?type=PAY_LATER_LEGAL&fullDocument=true" target="_blank">${this.payLaterLegals.documentName}</a>`,
              privacy: `<a href="${this.appUrl}pages/terms-and-conditions/${this.privacyLegals._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.privacyLegals.documentName}</a>`,
              generalWebsiteTerms: `<a href="${this.appUrl}pages/terms-and-conditions/${this.termsAndCondition._id}?type=DASHBOARD&fullDocument=true" target="_blank">${this.termsAndCondition.documentName}</a>`,
            }
          );
        }
      });
    },

    uploadImgInput(key) {
      this.$refs[`uploadImgInputRef${key}`][0].click();
    },

    updateCurrImg(input, key, fieldRow) {
      let file = input.target.files[0];
      fieldRow.selectedFile = file;
    },

    clearPreview(key, fieldRow) {
      if (fieldRow.hasOwnProperty('value') && fieldRow.value.length > 0) {
        fieldRow.value = "";
      }
      this.$refs[`uploadImgInputRef${key}`][0].value = "";

      if (this.inputArrayFiltered[key].hasOwnProperty("selectedFile") && typeof this.inputArrayFiltered[key].selectedFile === 'object') {
        this.$delete(this.inputArrayFiltered[key], 'selectedFile');
      }
      if (this.errors.has(fieldRow.label)) {
        this.errors.remove(fieldRow.label);
      }
    },

    showPreview(key) {
      return (
        (
          this.inputArrayFiltered[key].hasOwnProperty('value') &&
          typeof this.inputArrayFiltered[key].value === 'string' &&
          this.inputArrayFiltered[key].value.length > 0
        )
        ||
        (
          this.inputArrayFiltered[key].hasOwnProperty('selectedFile') &&
          typeof this.inputArrayFiltered[key].selectedFile === 'object'
        )
      )
    },

    gotoSelectedRef(refName) {
      if (refName) {
        const elmnt = document.getElementById(refName);
        elmnt.scrollIntoView({behavior: "smooth"});
      }
    },

    formatToCurrency(amount){
      if (!amount) {
        return amount;
      }

      const formatter = new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
      });
      return formatter.format(amount).replace("$","")+'.00';
    },

    showSuccessMessage(title = 'Success', message='Success'){
      this.$toast.success(<div class="container"><h3>{title}</h3><p>{message}</p></div>, {
        position: "top-right",
        timeout: 5000,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 0.1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: {
          iconClass: "material-icons text-success",
          iconChildren: "check_circle_outline",
          iconTag: "span"
        },
        rtl: false,
        message: message
      });
    },

    showErrorMessage(title = 'Error', message='Something went wrong.', time = false){
      this.$toast.error(<div class="container"><h3>{title}</h3><p>{message}</p></div>, {
        position: "top-right",
        timeout: time ? 3000 : false,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 0.1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: {
          iconClass: "material-icons text-danger",
          iconChildren: "highlight_off",
          iconTag: "span"
        },
        rtl: false,
        message: message
      });
    },

    showWarningMessage(title = 'Warning', message='Warning', time = false){
      this.$toast.warning(<div class="container"><h3>{title}</h3><p>{message}</p></div>, {
        position: "top-right",
        timeout: time ? 3000 : false,
        closeOnClick: false,
        pauseOnFocusLoss: false,
        pauseOnHover: false,
        draggable: false,
        draggablePercent: 0.1,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: {
          iconClass: "material-icons text-warning",
          iconChildren: "info_outline",
          iconTag: "span"
        },
        rtl: false,
        message: message
      });
    },

    idVerifyAuthorized(val) {
      this.authorized_to_use = val;
    },

    selectTab(index) {
      this.tabIndex = index;
      this.formData.customerSelectedPaymentMethod = index;

      if (this.tabIndex == "CARD") {
        this.paymentJSObject = undefined;
        this.initializePaymentObject();
        this.cvvSuccess = false;
        this.cardNameValid = false;
        this.isInvalidCvv = false;
        this.formData.name = "";
        this.formData.card_expiry_date = "";
        this.formData.exp_month = "";
        this.formData.exp_year = "";
        this.showCardNumberIsRequired = false;
        this.isCardAllowed = true;
        this.cardValid = true;
        this.showCvcIsRequired = false;
        this.showExpired = false;
        this.showExpDateIsRequired = false;
        this.dateSuccess = false;
        this.dateError = true;
        this.cardType = 'default';
        this.cardValidity = true;
        this.cardSuccess = false;
      } else {
        this.bsbSuccess = false;
        this.accountNameValid = false;
        this.accountNumberValid = false;
        this.formData.accountName = "";
        this.formData.bsb = "";
        this.formData.accountNumber = "";
        this.showBsbValid = true;
        this.bsbError = true;
        this.toc_acceptance_direct_debit = false;
      }

      this.payNowAcceptance = false;
      this.toc_acceptance = false;
    },

    acceptPayNowTerms() {
      this.payNowAcceptance = !this.payNowAcceptance;
    },

    acceptTerms() {
      this.toc_acceptance = !this.toc_acceptance;
    },

    acceptTermsNPP() {
      this.toc_acceptance_direct_debit = !this.toc_acceptance_direct_debit;
    },
    getSelelectedFields(event) {
      this.productCustomFields = [];
      this.inputArrayFiltered = [];
      this.inputArray = [];
      this.customFields = [];

      const paymentDetails = this.paymentDetails;

      if (this.paymentDetails.customFields && paymentDetails.customFields.length > 0 && paymentDetails.customFields[0].label) {
          this.customFields = JSON.parse(JSON.stringify(paymentDetails.customFields));
          this.customFields.map((item) => {
            item.fieldType = "custom";
            return item;
          });
          this.defaultCustomFields = [...this.customFields];
          this.customFields = _.filter(this.customFields, function (o) {
            return (o.displayToCustomer || o.editableByCustomer || o.requiredToSubmit);
          });
      }

      if (this.customFields.length > 0) {
        this.payNowAdditional = true;
      }

      if (paymentDetails.productCustomFields && paymentDetails.productCustomFields.length > 0 || paymentDetails.payLaterPlan && paymentDetails.payLaterPlan.length > 0) {
        let fields = [];
        if (event !== "pay-now") {
          const planData = this.paymentDetails.payLaterPlan.find((element) => {
            return  element._id == event;
          });
          this.productType = planData.currentPlan.productType;

          fields = planData.customFields;
        } else {
          fields = paymentDetails.productCustomFields;
        }

        if (fields && fields.length > 0) {
          this.productCustomFields = [ ...JSON.parse(JSON.stringify(fields)) ];
        }
        this.payNowAdditional = (this.customFields.length > 0);

        this.productCustomFields.map((item) => {
          item.fieldType = "product";
          return item;
        });

        const defaultProductAddressField = {...this.productAddressField };

        this.productCustomFields.forEach((e, index) => {
          if (e.type == "address") {
            if (e.value) {
              this.productAddressField = JSON.parse(e.value);
              if (!this.productAddressField.street_name) {
                this.productAddressField.street_name = this.productAddressField.street ? this.productAddressField.street : '';
              }
              if (e.displayToCustomer && !e.editableByCustomer && !e.requiredToSubmit) {
                this.productAddressField.rawAddress = `${this.productAddressField.street_name}, ${this.productAddressField.suburb}, ${this.productAddressField.state} ${this.productAddressField.postcode}, Australia`;
              } else {
                this.productAddressField.rawAddress = `${this.productAddressField.street_name}, ${this.productAddressField.suburb} ${this.productAddressField.state}, Australia`;
              }
              this.productAddressFieldArray[e._id] = this.productAddressField;
            } else {
              this.productAddressFieldArray[e._id] = defaultProductAddressField;
            }
          }
        });

        this.defaultCustomFields = [...this.defaultCustomFields, ...this.productCustomFields];

        //productField not viewable to customer is collected here to push to finpower
        this.productCustomFieldsNotViewable = _.filter(
          this.productCustomFields,
          function (o) {
            return (!o.displayToCustomer && !o.editableByCustomer && !o.requiredToSubmit);
          }
        );

        this.productCustomFields = _.filter(
          this.productCustomFields,
          function (o) {
            return (o.displayToCustomer || o.editableByCustomer || o.requiredToSubmit);
          }
        );

        this.customFields = [
          ...this.productCustomFields,
          ...this.customFields,
        ];
      }

      this.customFields.forEach((e, index) => {
        if (e.requiredToSubmit) {
          this.additionalOpen = true;
        }

        e.value = e.value ? e.value : "";
        this.inputArray.push(e);
      });

      this.inputArray.forEach((item) => {
        if (item.type == "currency" && (item.editableByCustomer || item.requiredToSubmit)) {
          item.value = item.value.replace("$ ", "");
          item.value = this.addZeroes(item.value);
        }
      });
    }
  },

  created() {
    this.formData.sendConfirmText = this.formData.email;
      let paymentRequestId = this.$route.params.id || this.$route.params.paymentRequestId;
      this.$vs.loading();
      this.getPaymentDetails(paymentRequestId)
        .then(async (response) => {
          let responseData = response.data.data;
          this.card_options = responseData.paymentCards;
          this.paymentDetails = responseData.paymentDetails;
          this.paymentRequestId = responseData.paymentDetails._id;

          if (responseData.transactions) {
            this.prTransactionSchedule = responseData.transactions;
          }

          const requestTemplate = responseData.paymentDetails && responseData.paymentDetails.paymentRequestTemplate ? responseData.paymentDetails.paymentRequestTemplate : null;
          const planId = requestTemplate && requestTemplate.paymentOptions[0] && requestTemplate.paymentOptions[0].payLaterPlan ? requestTemplate.paymentOptions[0].payLaterPlan[0] : "";

          if (planId) {
            this.planDisplayName = responseData.paymentDetails.partnerId.payLaterPlans.find((plan) => plan.customPlanId === planId).displayName;
          }

          if (responseData.paymentDetails.paymentStatus === "Info-required") {
            this.$vs.loading.close();
            return this.$router.push({ name: "payment-forbidden" });
          }

          if (responseData.paymentDetails.requestOptions.includes("pay-later")) {
            await this.getCardsForPayLater();
          }

          if (responseData.paymentDetails.requestOptions[0] == "pay-now") {
            this.card_options = responseData.paymentCards;
          }

          if (responseData.paymentDetails.requestOptions[0] == "pay-later") {
            this.card_options = this.cardsForPaylater;
          }

          this.paymentResponseData = responseData;
          this.paymentRequestDetail = responseData.paymentDetails;
          this.paymentRequestDetail.requestOptions = this.$lodashHelper.checkObjectKey(this.paymentRequestDetail, "requestOptions", []);
          this.footerBind = true;
          this.redirectData = {
            success: {
              header: this.paymentRequestDetail.successHeading,
              body: this.paymentRequestDetail.successBody,
              url: this.paymentRequestDetail.successUrl,
              btnText: this.paymentRequestDetail.successButtonText,
            },
            fail: {
              header: this.paymentRequestDetail.failureHeading,
              body: this.paymentRequestDetail.failureBody,
              url: this.paymentRequestDetail.failureUrl,
              btnText: this.paymentRequestDetail.failureButtonText,
              enablepaynow: this.paymentRequestDetail.failurePayNowOption,
            },
          };

          this.$vs.loading.close();

          if (responseData.paymentDetails.requestType === "recurring") {
            this.isRecurring = true;
            this.page_type = "recurring";
            this.paymentRequestFrequency = responseData.paymentDetails.frequency;
            this.prepareSchedule();
          }

          this.paymentRID = responseData.paymentDetails.paymentRequestId;
          this.loginUrl = `${this.customerPortalUrl}${responseData.paymentDetails.partnerId.id}/customer-login`;
          this.merchantId = responseData.paymentDetails.partnerId.id;
          this.formData.amount = parseFloat(responseData.paymentDetails.amount.$numberDecimal);
          this.total = this.formData.amount;
          this.formData.reference = responseData.paymentDetails.reference;
          this.formData.senderDetails = responseData.paymentDetails.senderDetails;
          this.formData.mobile = responseData.paymentDetails.payeePhone;

          if (this.formData.mobile) {
            this.formData.maskedMobileNumber = this.formData.mobile;
          }

          this.formData.email = responseData.paymentDetails.payeeEmail;
          this.formData.sendConfirmText = responseData.paymentDetails.payeeEmail;

          if (responseData.paymentDetails.customFields && responseData.paymentDetails.customFields.length > 0 && responseData.paymentDetails.customFields[0].label) {
            this.customFields = JSON.parse(JSON.stringify(responseData.paymentDetails.customFields));
            this.customFields.map((item) => {
              item.fieldType = "custom";
              return item;
            });
            this.defaultCustomFields = [...this.customFields];
            this.customFields = _.filter(this.customFields, function (o) {
              return (o.displayToCustomer || o.editableByCustomer || o.requiredToSubmit);
            });
          }

          this.payNowAdditional = (this.customFields.length > 0);

          if (responseData.paymentDetails.productCustomFields && responseData.paymentDetails.productCustomFields.length > 0 && responseData.paymentDetails.productCustomFields[0].label) {
            this.productCustomFields = [ ...JSON.parse(JSON.stringify(responseData.paymentDetails.productCustomFields)) ];
            this.productCustomFields.map((item) => {
              item.fieldType = "product";
              return item;
            });

            const defaultProductAddressField = { ...this.productAddressField };

            this.productCustomFields.forEach((e, index)=>{
              if (e.type == "address") {
                if (e.value) {
                  this.productAddressField = JSON.parse(e.value);
                  if (!this.productAddressField.street_name) {
                    this.productAddressField.street_name = this.productAddressField.street ? this.productAddressField.street : '';
                  }
                  if (e.displayToCustomer && !e.editableByCustomer && !e.requiredToSubmit) {
                    this.productAddressField.rawAddress = `${this.productAddressField.street_name}, ${this.productAddressField.suburb}, ${this.productAddressField.state} ${this.productAddressField.postcode}, Australia`;
                  } else {
                    this.productAddressField.rawAddress = `${this.productAddressField.street_name}, ${this.productAddressField.suburb} ${this.productAddressField.state}, Australia`;
                  }
                  this.productAddressFieldArray[e._id] = this.productAddressField;
                } else {
                  this.productAddressFieldArray[e._id] = defaultProductAddressField;
                }
              }
            });

            this.defaultCustomFields = [...this.defaultCustomFields, ...this.productCustomFields];

            this.productCustomFieldsNotViewable = _.filter(
              this.productCustomFields,
              function (o) {
                return (!o.displayToCustomer && !o.editableByCustomer && !o.requiredToSubmit);
              }
            );

            this.productCustomFields = _.filter(
              this.productCustomFields,
              function (o) {
                return (o.displayToCustomer || o.editableByCustomer || o.requiredToSubmit);
              }
            );

            this.customFields = [ ...this.productCustomFields, ...this.customFields ];
          }

          this.customFields.forEach((e, index) => {
            if (e.requiredToSubmit) {
              this.additionalOpen = true;
            }

            e.value = e.value ? e.value : "";
            this.inputArray.push(e);
          });

          this.inputArray.forEach((item) => {
            if (item.type == "currency" && (item.editableByCustomer || item.requiredToSubmit)) {
              item.value = item.value.replace("$ ", "");
              item.value = this.addZeroes(item.value);
            }
          });

          this.formData.message = responseData.paymentDetails.message;
          this.formData.attachments = responseData.paymentDetails.files;

          if (this.paymentRequestDetail.createdBy) {
            this.formData.payment_request_from.name = responseData.paymentDetails.createdBy.fullName;
            this.formData.payment_request_from.email = responseData.paymentDetails.createdBy.email;
            this.formData.payment_request_from.phone_number = responseData.paymentDetails.createdBy.phoneNumber;
          }

          if (this.formData.payment_request_from.phone_number && this.formData.payment_request_from.phone_number !== "") {
            let phoneNumber = this.formData.payment_request_from.phone_number;

            if (phoneNumber.includes(" ")) {
              this.formData.payment_request_from.phone_number = phoneNumber;
            } else {
              let maskedNumber = phoneNumber.match(/(\d{4})(\d{3})(\d{3})/);
              this.formData.payment_request_from.phone_number = maskedNumber[1] + " " + maskedNumber[2] + " " + maskedNumber[3];
            }
          }

          let cardWithSurchargeStatus = this.card_options.find((card) => {
            if (parseFloat(card.surcharge) > 0) {
              return card;
            }
          });

          if (!cardWithSurchargeStatus) {
            this.cardWithSurcharge = false;
          }

          if (response.data.data.paymentDetails.requestType === "one-off") {
            let requestOptions = response.data.data.paymentDetails.requestOptions;

            if (requestOptions.length > 0) {
              let findPaylater = requestOptions.find((requestOption) => requestOption === "pay-later");
              this.page_type = findPaylater ? "paylater" : "normal";
            } else {
              this.page_type = "normal";
            }
          } else {
            this.page_type = "recurring";
          }

          if (this.page_type === "paylater") {
            const payLaterData = this.paymentRequestDetail.payLaterPlan;

            for (let i = 0; i< payLaterData.length; i++) {
              let quoteData = payLaterData[i];

              if (!this.paymentRequestDetail.requestOptions.includes("pay-now")) {
                this.formData.payment_type = quoteData.currentPlan._id;
              }

              quoteData.plan_name = quoteData.currentPlan.productName;
              quoteData.payment_plan = quoteData.currentPlan;
              quoteData.productText = quoteData.currentPlan.productText;

              if (quoteData.currentPlan.productType === "B2C_BNPL") {
                if (quoteData.planText && quoteData.planText.length > 0) {
                  quoteData.planDescription = quoteData.planText[0] + '\r\n' + (quoteData.planText[1] ? quoteData.planText[1] : "");
                }
              }

              this.quote_data.push(quoteData);
              this.productType = quoteData.currentPlan.productType;
            }

            await this.fetchPlanByCustomFieldId();
          }

          let reqType = response.data.data.paymentDetails.requestType;
          let reqOptions = response.data.data.paymentDetails.requestOptions;

          if (reqType == "one-off" && reqOptions.length == 1 && reqOptions.includes("pay-later")) {
            this.savePaymentType(this.quote_data[0].currentPlan._id);
          } else {
            this.till_public_key =
            responseData.paymentDetails.accountId.publicIntegration;
            this.savePaymentType("pay-now");
          }

          this.paymentBind = true;
          this.idMatrix.enquiryId = response.data.data.paymentDetails.idMatrixVerifications && response.data.data.paymentDetails.idMatrixVerifications[0] && response.data.data.paymentDetails.idMatrixVerifications[0].enquiryId;
          this.idMatrix.verifyCount = response.data.data.paymentDetails.idMatrixVerifications ? response.data.data.paymentDetails.idMatrixVerifications.length : 0;

          if (response.data.data.paymentDetails.idMatrixVerifications &&  response.data.data.paymentDetails.idMatrixVerifications. length > 0) {
            let lastIdMatrixData = response.data.data.paymentDetails.idMatrixVerifications[response.data.data.paymentDetails.idMatrixVerifications.length - 1];

            if (lastIdMatrixData) {
              this.hideIdErrorBlock = true;
              this.idMatrix.verified = lastIdMatrixData.overallOutcome === 'ACCEPT' ? true: false;
            }
          }

          this.idMatrixData = response.data.data.paymentDetails.idMatrixVerifications;

          if (this.paymentRequestDetail.requestType !== "recurring" && this.paymentRequestDetail.requestOptions.length < 1) {
            this.hidePayNow = true;
          }

          if ((this.paymentRequestDetail.failurePayNowOption === false || this.paymentRequestDetail.failurePayNowOption === true) &&
              this.paymentRequestDetail.failureUrl &&
              this.paymentRequestDetail.failureDisplayText &&
              this.idMatrix.verifyCount > 2) {

            if (this.paymentRequestDetail.failurePayNowOption === true) {
              this.showDirectPayNowFromApiConfig = true;
            } else if (this.paymentRequestDetail.failurePayNowOption === false) {
              this.showDirectPayNowFromApiConfig = false;
            }
          }
        }).catch((ex) => {
          this.$vs.loading.close();
          sentryLog(ex);
          this.$router.push({ name: "payment-error" });
        });

      this.fetchPrivacyLegals();
      Validator.extend("customCurrency", {
        getMessage: (field) => `${field} field is required`,
        validate(value) {
          return new Promise((resolve) => {
            resolve({
              valid: value <= 0 ? false : !!value,
              data: value > 0 ? undefined : { message: "Not this value" },
            });
          });
        },
      });
      Validator.extend("cardNameFormat", {
        getMessage: () => "Name on card should contain at least one letter",
        validate: (value) => value.match(/[a-zA-Z]/g) !== null,
      });
      Validator.extend("alphanumFormat", {
        getMessage: () => "Invalid account number",
        validate: (value) => value.match(/^[a-zA-Z0-9]+$/) !== null,
      });
  },

  directives: {
    mask
  },
};
</script>
