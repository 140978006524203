<template>
  <vs-row class="payments-page justify-start">
    <vs-col vs-w="8" vs-lg="8" vs-sm="12" vs-xs="12" class="">
      <vs-row class="w-full">
        <vs-col vs-w="12">
          <h3 class="font-medium
              text-lg
              md:text-xl
              lg:text-2xl
              mb-3
              lg:mb-4
              mt-6
              lg:mt-10">
            Payment request from
            {{
              formData.senderDetails
                ? formData.senderDetails.fullname
                : formData.payment_request_from.name
            }}
          </h3>
        </vs-col>
        <div
          class="
            flex
            justify-start
            w-full
          "
        >
          <div class="mr-5 md:mr-8">
            <p class="flex items-center">
              <span class="flex items-center">
                <img :src="phone" alt="phone" />
              </span>
              <a
                class="ml-2
                  md:text-base md:leading-normal
                  sm:text-sm
                  text-xs
                  leading-tight"
                :href="`tel:${
                  formData.senderDetails
                    ? formData.senderDetails.senderPhone
                    : formData.payment_request_from.phone_number
                }`"
                >{{
                  formData.senderDetails
                    ? formData.senderDetails.senderPhone
                    : formData.payment_request_from.phone_number
                }}</a
              >
            </p>
          </div>
          <div v-if="formData.payment_request_from.phone_number !== '' || formData.senderDetails.senderEmail !== '' " class="mr-5 md:mr-8">
            <p
              class="
                flex
                items-center
                lg:justify-start
                md:justify-start
                justify-end
              "
            >
              <span class="flex items-center">
                <img :src="mail" alt="mail" />
              </span>
              <a
                class="ml-2
                  text-xs
                  leading-tight
                  sm:text-sm
                  md:text-base md:leading-normal"
                :href="`mailto:${
                  formData.senderDetails
                    ? formData.senderDetails.senderEmail
                    : formData.payment_request_from.email
                }`"
                >{{
                  formData.senderDetails
                    ? formData.senderDetails.senderEmail
                    : formData.payment_request_from.email
                }}</a
              >
            </p>
          </div>
        </div>

        <vs-col class="mb-4 md:mb-8 lg:mb-10" vs-w="12">
          <p class="mt-4
              text-xs
              leading-tight
              md:text-sm
              lg:text-base lg:leading-normal lg:mt-6" v-if="formData.message && formData.message !== ''">{{ formData.message }}</p>
        </vs-col>
      </vs-row>
    </vs-col>
    <!-- <vs-col vs-w="12" class="w-full mb-3">
      <hr class="mb-12" style="background:none;border-top:1px solid #bdbdbd" />
    </vs-col> -->
  </vs-row>
</template>

<script>
const phone = require("@/assets/images/phone.png");
const mail = require("@/assets/images/mail.png");
const S3FileGenerator = require("@/views/components/S3FileGenerator");

export default {
  name: "RequestHeader",
  props: ["formData"],
  components: {
    S3FileGenerator
  },

  data() {
    return {
      phone: phone,
      mail: mail,
    };
  },
};
</script>
