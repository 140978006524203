<template>
  <div class="slideup" :class="{ slidedown: isActive, 'later-slide': !isPayLater }" @click="isHidden">
    <vs-card class="your-pay-card mb-0 sm:mb-2 md:mb-4 lg:mb-6 bg-white" :class="{ recurr: paymentTypeForSummary === 'pay-later', recurrSummary: isRecurring }">
      <div slot="header">
        <h5 class="card-title text-white text-xl font-normal">
          {{ paymentTypeForSummary == "pay-later" && paymentRequestDetail.payLaterPlan && paymentRequestDetail.payLaterPlan.length ? dataLabel.cardTitle : "Your payment" }}
          <span class="text-lg text-white float-right mobile-text leading-normal" v-if="paymentRequestDetail.type && paymentRequestDetail.type=='static'">
            ${{ addZeroes(paymentRequestDetail.amount ? paymentRequestDetail.amount : 0.00) }}
          </span>
          <span class="text-lg text-white float-right mobile-text leading-normal" v-else>
            ${{ addZeroes(total ? total : paymentRequestDetail.amount.$numberDecimal) }}
          </span>
        </h5>
      </div>

      <!-- one-off payment summary -->
      <div v-if="paymentTypeForSummary == 'pay-now' && !isRecurring" class="">
        <vs-row class="flex justify-between pb-2">
          <span class="text-base font-light leading-tight">Purchase Amount</span>
          <span class="text-base font-light leading-tight">
            ${{ addZeroes(paymentRequestDetail.amount.$numberDecimal) }}
          </span>
        </vs-row>
        <vs-row class="flex justify-between pb-2" v-if="!isNaN(addZeroes(getFeesFromPercentage()))">
          <span class="text-base font-light leading-tight">Card surcharge</span>
          <span class="text-base font-light leading-tight">$ {{ addZeroes(getFeesFromPercentage()) }}</span>
        </vs-row>
        <hr class="mt-2 mb-4 line-hr" />
        <vs-row class="flex justify-between">
          <span class="text-xl font-normal dark-blue">Total</span>
          <span class="text-xl font-normal dark-blue">
            ${{ addZeroes(total ? total : paymentRequestDetail.amount.$numberDecimal) }}
          </span>
        </vs-row>
      </div>

      <!-- recurring payment summary -->
      <div v-if="isRecurring" class="recurring-pads">
        <vs-row class="flex justify-evenly pay-later-card p-4">
          <div class="flex flex-col justify-center items-center">
            <img :src="update" alt="update" />
            <span class="dark-blue text-xs mt-2"> Every</span>
            <span class="dark-blue text-xs">
              {{ ` ${paymentRequestDetail.frequency.interval} ${paymentRequestDetail.frequency.every}` }}
            </span>
          </div>
          <div class="flex flex-col justify-center items-center">
            <img :src="playCircle" alt="playcircle" />
            <span class="dark-blue text-xs mt-2">Starts</span>
            <span class="dark-blue text-xs">{{ formatScheduleDate(paymentRequestDetail.frequency.startDate) }}</span>
          </div>
          <div class="flex flex-col justify-center items-center">
            <img :src="stopCircle" alt="stopcircle" />
            <span class="dark-blue text-xs mt-2"> Ends <span v-if="paymentRequestDetail.frequency.endAfterNumberOfPayments">after</span></span>
            <span class="text-center text-xs dark-blue" v-if="paymentRequestDetail.frequency.endAfterNumberOfPayments">
              {{ paymentRequestDetail.frequency.endAfterNumberOfPayments }} payments
            </span>
            <span v-if="paymentRequestDetail.frequency.endDate" class="dark-blue text-xs">
              {{ formatScheduleDate(paymentRequestDetail.frequency.endDate) }}
            </span>
          </div>
        </vs-row>

        <div class="p-6">
          <vs-row class="flex justify-between pb-2">
            <span class="text-base font-light leading-tight">Recurring amount</span>
            <span class="text-base font-light leading-tight">${{ addZeroes(paymentRequestDetail.amount.$numberDecimal) }}</span>
          </vs-row>
          <vs-row class="flex justify-between pb-3">
            <span class="text-base font-light">Card surcharge</span>
            <span class="text-base font-light">
              ${{ addZeroes(getFeesFromPercentage() ? getFeesFromPercentage() : "0") }}
            </span>
          </vs-row>

          <hr class="mt-2 mb-4 line-hr" />
          <vs-row class="flex justify-between">
            <span class="text-xl font-normal dark-blue leading-relaxed">Total recurring <br />amount</span>
            <span class="text-xl font-normal dark-blue">
              ${{ addZeroes(total ? total : paymentRequestDetail.amount.$numberDecimal) }}
            </span>
          </vs-row>

        </div>
      </div>

      <!-- paylater payment summary -->
      <div v-if="paymentTypeForSummary == 'pay-later' && scheduleBind && paymentRequestDetail.payLaterPlan && paymentRequestDetail.payLaterPlan.length &&
          paymentRequestDetail.payLaterPlan[0].currentPlan.productType == 'B2C_BNPL'">
        <vs-row class="flex justify-evenly pay-later-card p-4">
          <div class="flex flex-col justify-center items-center">
            <img :src="update" alt="update" />
            <span class="dark-blue text-xs mt-2"> Every </span>
            <span class="dark-blue text-xs">{{ everyNumber }} {{ weekOrMonthOrDay }}</span>
          </div>
          <div class="flex flex-col justify-center items-center">
            <img :src="playCircle" alt="playcircle" />
            <span class="dark-blue text-xs mt-2">Starts</span>
            <span class="dark-blue text-xs">{{ formatScheduleDate(paymentSchedules[0].value) }}</span>
          </div>
          <div class="flex flex-col justify-center items-center">
            <img :src="stopCircle" alt="stopcircle" />
            <span class="dark-blue text-xs mt-2">Ends after</span>
            <span class="dark-blue text-xs">{{ paymentRequestDetail.payLaterPlan[0].numberOfPayments }} payments</span>
          </div>
        </vs-row>
        <vs-row class="p-6">
          <div class="w-full flex flex-col">
            <vs-col vs-w="12">
              <vs-table :data="schedule" class="payment-schedule-table summary-table">
                <template slot="thead">
                  <vs-th class="text-base font-medium" style="color: #757575">{{ dataLabel.dateLabel }}</vs-th>
                  <vs-th class="text-base font-medium" style="color: #757575">{{ dataLabel.amountLabel }}</vs-th>
                </template>
                <template slot-scope="{ data }">
                  <vs-tr :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="tr.date" class="font-light text-base">{{ isToday(tr.date) ? "Today" : formatScheduleDate(tr.date) }}</vs-td>
                    <vs-td :data="tr.amount" class="sec-row-payment font-light text-base">$ {{ addZeroes(tr.amount) }}</vs-td>
                  </vs-tr>
                </template>
              </vs-table>
            </vs-col>
            <hr class="mb-4 mt-4 line-hr" />
            <div class="flex justify-between">
              <span class="text-xl font-normal dark-blue">{{ dataLabel.totalAmountLabel }}</span>
              <span class="text-xl font-normal dark-blue">$ {{ addZeroes(paymentRequestDetail.payLaterPlan[0].totalCustomerPayable) }}</span>
            </div>
          </div>
          <vs-row class="flex justify-between mt-4">
            <span class="text-xs leading-tight font-light mb-2" v-if="paymentRequestDetail.payLaterPlan[0].feeModel === 'MSF_ADDED'">
              {{ parseContent(paymentRequestDetail.payLaterPlan[0].currentPlan.feeText ? paymentRequestDetail.payLaterPlan[0].currentPlan.feeText : "") }}
            </span>
          </vs-row>
        </vs-row>
      </div>

      <!-- paylater B2C_BNPO payment summary -->
      <div
        v-if="
          !isRecurring &&
          paymentRequestDetail.payLaterPlan &&
          paymentRequestDetail.payLaterPlan.length &&
          (paymentRequestDetail.payLaterPlan[0].currentPlan.productType ==
            'B2C_BNPO' ||
            paymentRequestDetail.payLaterPlan[0].currentPlan.productType ==
              'B2C_BNPO_NC') &&
          paymentTypeForSummary !== 'pay-now'
        "
        class="p-0"
      >
        <vs-row>
          <div class="flex items-center gray py-4 px-6 w-full">
            <img :src="calenderImage" alt="calenderImage" width="18" height="20"/>
            <span class="dark-blue text-xs pl-4 leading-tight">
              {{ dataLabel.message }}
              {{ formatScheduleDate(paymentRequestDetail.payLaterPlan[0].maturityDate)}}
            </span>
          </div>
        </vs-row>
        <div class="p-6">
        <vs-row class="flex justify-between pb-2">
          <span class="text-base font-light leading-tight">{{ dataLabel.purchaseAmountLabel }}</span>
          <span class="text-base font-light leading-tight">$ {{ addZeroes(paymentRequestDetail.amount.$numberDecimal) }}</span>
        </vs-row>
        <vs-row class="flex justify-between pb-2" v-if="paymentRequestDetail.payLaterPlan[0].feeModel === 'MSF_ADDED'">
          <span class="text-base font-light leading-tight">{{ dataLabel.feeLabel }}</span>
          <span class="text-base font-light leading-tight">
            ${{ addZeroes(parseFloat(paymentRequestDetail.payLaterPlan[0].totalCustomerPayable) - parseFloat(paymentRequestDetail.payLaterPlan[0].purchaseAmount))}}
          </span>
        </vs-row>
        <hr class="mt-2 mb-4 line-hr" />
        <vs-row class="flex justify-between">
          <span class="text-xl font-normal dark-blue">{{ dataLabel.totalAmountLabel }}</span>
          <span class="text-xl font-normal dark-blue">
            ${{ addZeroes(paymentRequestDetail.payLaterPlan[0].totalCustomerPayable) }}
          </span>
        </vs-row>
        </div>
      </div>

       <!-- hosted payment page payment summary -->
      <div v-if="paymentRequestDetail.type && paymentRequestDetail.type=='static'">
        <vs-row class="flex justify-between pb-2">
          <span class="text-base font-light leading-tight">Purchase Amount</span>
          <span class="text-base font-light leading-tight">
            ${{ addZeroes(paymentRequestDetail.amount) }}
          </span>
        </vs-row>
        <vs-row class="flex justify-between" v-if="!isNaN(addZeroes(getFeesFromPercentage()))">
          <span class="text-base">Fees</span>
          <span class="text-base">$ {{ addZeroes(getFeesFromPercentage()) }}</span>
        </vs-row>
        <hr class="mt-2 mb-4 line-hr" />
        <vs-row class="flex justify-between">
          <span class="text-xl font-normal dark-blue">Total</span>
          <span class="text-xl font-normal dark-blue">$ {{addZeroes(this.total)}}</span>
        </vs-row>

      </div>
    </vs-card>
  </div>
</template>

<script lang="ts">
import moment from "moment";
import { mapActions } from "vuex";
const update = require("@/assets/images/update.png");
const playCircle = require("@/assets/images/play-circle.png");
const stopCircle = require("@/assets/images/stop-circle.png");
const calenderImage = require("@/assets/images/calender.png");

export default {
  props: [
    "paymentRequestDetail",
    "card_status",
    "card_options",
    "paymentTypeForSummary",
    "prTransactionSchedule"
  ],
  data() {
    return {
      everyNumber: "1",
      weekOrMonthOrDay: "",
      weekOrMonthRecurring: "",
      fee: "0",
      total: "",
      isActive: true,
      isRecurring: false,
      isOneoff: false,
      isPayLater: false,
      senderType: "default",
      active_el: true,
      overrideDefault: true,
      senderDisabled: true,
      displayOverride: true,
      update: update,
      playCircle: playCircle,
      stopCircle: stopCircle,
      calenderImage: calenderImage,
      scheduleBind: false,
      productType: "",
      msf: "0",
      dataLabel: {},
      schedule: []
    };
  },
  methods: {
    ...mapActions("paymentRequest", ["getPlanByCustomPlanId"]),
    isHidden() {
      if (this.isActive) {
        this.isActive = false;
      } else {
        this.isActive = true;
      }
    },

    moment(date) {
      return moment(date).format("D-MMM-YY");
    },
    formatScheduleDate(date) {
      return moment(date).format("DD MMM YYYY");
    },

    getFeesFromPercentage() {
      let matchCardType = this.card_options.find((card_option) => {
        return card_option.card.slug === this.card_status.cardType;
      });

      if (matchCardType) {
        this.fee = (((this.paymentRequestDetail.amount.$numberDecimal || this.paymentRequestDetail.amount) / 100) * matchCardType.surcharge).toFixed(2);
        this.total = parseFloat(this.fee) + parseFloat(this.paymentRequestDetail.amount.$numberDecimal||this.paymentRequestDetail.amount);
        this.$emit("changeTotal", this.total);
        return this.fee;
      }

      if (matchCardType == undefined){
        this.total= parseFloat(this.paymentRequestDetail.amount.$numberDecimal||this.paymentRequestDetail.amount);
        this.$emit("changeTotal", this.total);
      }
    },
    getFeesForBNPL() {},

    isToday(date) {
      return moment(date).isSame(moment(), "day");
    },

    addZeroes(num) {
      num = String(num);
      return Number(num).toLocaleString(undefined, {
        minimumFractionDigits: 2,
      });
    },

    async getPaylaterSchedule() {
      try {
        this.msf = this.paymentRequestDetail.payLaterPlan[0].msf;

        const total = this.prTransactionSchedule.find(item => item.type == "Total");
        const disbursement = this.prTransactionSchedule.find(item => item.type == "Disbursement");

        this.schedule = this.prTransactionSchedule.filter(item => item.type == "Payment");

        if (this.paymentRequestDetail.payLaterPlan[0].currentPlan.productType == "B2C_BNPL") {
          this.payLaterSchedule = this.schedule.map((item) => {
            return {
              label: item.amount,
              value: new Date(item.date),
            };
          });

          this.paymentSchedules = this.payLaterSchedule;
          this.totalNumberOfPayments = this.payLaterSchedule.length;
        }

        this.totalCustomerPayable = total.amount;
        this.merchantAmount = disbursement.amount;
        this.scheduleBind = true;
      } catch(ex) {};
    },

    async getPlan(planId) {
      await this.getPlanByCustomPlanId(planId).then((res) => {
        const data = res.data.data[0];
        this.dataLabel = {
          dateLabel: data.dateLabel && data.dateLabel != "" ? data.dateLabel : "Payement date",
          amountLabel: data.amountLabel && data.amountLabel != "" ? data.amountLabel : "Amount",
          cardTitle: data.cardTitle && data.cardTitle != "" ? data.cardTitle : "Your payment",
          totalAmountLabel: data.totalAmountLabel != "" ? data.totalAmountLabel : "Total",
          purchaseAmountLabel: data.purchaseAmountLabel && data.purchaseAmountLabel != "" ? data.purchaseAmountLabel : "Purchase Amount",
          feeLabel: data.feeLabel && data.feeLabel != "" ? data.feeLabel : "Fee",
          message: data.message && data.message != "" ? data.message : "Our share of proceeds will be deducted on ",
        };
      }).catch((ex) => {});
    },

    parseContent(data) {
      if (!data) {
        return "";
      }

      data = data.split("{{Fee %}}").join(this.paymentRequestDetail.payLaterPlan[0].currentPlan.msf + "%");
      data = data.split("{{Fee $ amount}}").join("$"+this.addZeroes(this.calculateFees));

      return data;
    }
  },

  mounted() {
    if (this.paymentRequestDetail.requestType && this.paymentRequestDetail.requestType.includes("pay-later")) {
      this.total = this.paymentRequestDetail.payLaterPlan[0].totalCustomerPayable;
    }

    if (this.paymentRequestDetail.requestType && this.paymentRequestDetail.requestType == "recurring") {
      this.isRecurring = true;
      this.weekOrMonthRecurring = this.paymentRequestDetail.frequency.every;
    }

    if (this.paymentRequestDetail.payLaterPlan && this.paymentRequestDetail.payLaterPlan.length) {
      this.getPlan(this.paymentRequestDetail.payLaterPlan[0].currentPlan.planId);
      this.getPaylaterSchedule();

      switch (this.paymentRequestDetail.payLaterPlan[0].paymentFrequency) {
        case "WEEKLY":
          this.weekOrMonthOrDay = "Week";
          break;

        case "FORTNIGHTLY":
          this.everyNumber = "1";
          this.weekOrMonthOrDay = "fortnight";
          break;

        case "DAILY":
          this.weekOrMonthOrDay = "Day";
          break;

        case "MONTHLY":
          this.weekOrMonthOrDay = "Month";
          break;
      }
    }

    setTimeout(() => this.isActive = false, 4000);
  },
  computed: {
    user() {
      return this.$store.state.AppActiveUser;
    },

    calculateFees() {
      const fees = (parseFloat(this.paymentRequestDetail.payLaterPlan[0].totalCustomerPayable) - parseFloat(this.paymentRequestDetail.payLaterPlan[0].merchantAmount)).toFixed(2);
      const feeAmount = (this.msf * parseFloat(this.paymentRequestDetail.payLaterPlan[0].purchaseAmount)) / 100;

      if (this.paymentRequestDetail.payLaterPlan[0].minimumMsfFeeAmount && this.paymentRequestDetail.payLaterPlan[0].minimumMsfFeeAmount > feeAmount) {
        return fees;
      }

      return `${fees} (${this.msf}%)`;
    }
  },
};
</script>

